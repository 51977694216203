<template>
 

 




<div
      class="grid auto-rows-min"
      :style="{
            backgroundImage: DV_backgroundImageUrl,
          }"
        :class="{
          'p1-section-1-wide-tablet': DV_activeRenderMode == DV_renderModeTabletWide,
          'p1-section-1-narrow-tablet': DV_activeRenderMode == DV_renderModeTabletNarrow,
          'p2-section-1-above-wide-tablet': DV_activeRenderMode > DV_renderModeTabletWide,
          }"
    >
    <!-- alignment spacer 1 for top of curve in background image-->
      <span
          class="row-start-1 col-start-1"
          :class="{
            'w-[13.1vw]': DV_activeRenderMode === DV_renderModeDesktop,
            'w-[12vw]': DV_activeRenderMode === DV_renderModeDesktopWide,
            'w-[3vw]': DV_activeRenderMode === DV_renderModeTabletWide,
            'w-[6vw]': DV_activeRenderMode === DV_renderModeTabletNarrow,
            'w-[9vw]': DV_activeRenderMode <= DV_renderModePhoneNarrow,
            }"
      ></span>

      <nav class="flex items-center"
          :class="{
          'row-start-1 col-start-1 col-span-8 flex-row items-center mx-auto gap-10 justify-between': DV_activeRenderMode >= DV_renderModeTabletWide,
          'row-start-1 col-start-7 col-span-2 flex-col gap-8 justify-center ml-3': DV_activeRenderMode <= DV_renderModeTabletNarrow,
           }">
            <a 
           v-if="DV_activeRenderMode >= DV_renderModeTabletNarrow"
           class="text-center px-8 py-2 text-xl rounded font-semibold green-gradient cursor-pointer"
           @click.stop="showScheduleDemoModal"
           >Schedule A Demo</a>
           <!-- <a 
           class="text-center py-2 text-xl rounded font-semibold green-gradient cursor-pointer" 
           :class="
           {'px-8': DV_activeRenderMode > DV_renderModePhoneNarrow,
           'px-2' : DV_activeRenderMode <= DV_renderModePhoneNarrow,
          }"
           :href="DV_signUpUrl">Create An Account</a> -->
                     

          <a
          v-if="DV_activeRenderMode > DV_renderModeTabletNarrow"
          class="text-center px-8 py-2 text-xl rounded font-semibold green-gradient cursor-pointer" href="/home">Home</a>
      </nav>

      <div
            class="flex flex-row items-center row-start-1"
            :class="{
              'col-span-5 col-start-2': DV_activeRenderMode === DV_renderModeTabletNarrow,
              'col-span-6 col-start-1 pl-3 mt-2': DV_activeRenderMode <= DV_renderModePhoneNarrow,
              'col-start-8 mr-12': DV_activeRenderMode === DV_renderModeDesktop,
              'col-start-8': DV_activeRenderMode === DV_renderModeTabletWide,
              'col-start-7': DV_activeRenderMode === DV_renderModeDesktopWide
          }"
          @click.stop="gotoHomePage"
          >
            <img
              :class="{
                'w-[3.5vw] h-[3.5vw] max-h-[7vh] max-w-[7vh]': DV_activeRenderMode >= DV_renderModeTabletWide,
                'w-[10vw] h-[10vw]': DV_activeRenderMode === DV_renderModeTabletNarrow,
                'w-[14vw] h-[14vw]': DV_activeRenderMode === DV_renderModePhoneNarrow,
                'w-[12.1vh] h-[12.1vh]': DV_activeRenderMode === DV_renderModePhoneWide,

              }"
              src="../assets/synergy_icon.png"
            />
            <div class="flex flex-col gap-0 items-center ml-2">
              <span 
              :class="{
                'text-2xl font-extrabold tracking-widest leading-5': DV_activeRenderMode !== DV_renderModeTabletNarrow,
                'text-7xl font-extrabold tracking-widest leading-11': DV_activeRenderMode === DV_renderModeTabletNarrow,
                }"
              >SYNERGY</span>
              <span 
              :class="{
              'text-xl font-normal tracking-tigher': DV_activeRenderMode !== DV_renderModeTabletNarrow,
              'text-6xl font-normal tracking-tigher' : DV_activeRenderMode === DV_renderModeTabletNarrow
             }">Auto Solutions</span>
            </div>
          </div>


    <div class="row-start-3 col-start-1 col-span-8 mx-auto bg-white "
    :class="{
        'w-[80vw] mt-10 mb-5 pb-2 pt-2 border border-gray-900 rounded': DV_activeRenderMode >= DV_renderModeTabletWide,
        'w-[90vw] mt-5 mb-5 pb-2 pt-2': DV_activeRenderMode <= DV_renderModeTabletNarrow}">
      <div class="mx-auto max-w-5xl">
        <h2 class="text-2xl text-center font-bold leading-7 tracking-tight text-gray-900">Frequently asked questions</h2>
        <p class="mt-2 text-base text-center leading-2 text-gray-600">Can’t find the answer you’re looking for? Reach out to our <a href="mailto:info@texnrewards.com" class="font-semibold text-indigo-600 hover:text-indigo-500">customer support</a> team.</p>
        
        <!-- Uncomment to show text with expand collapse design (Make sure to comment lower section) -->
        <!-- <div v-for="(questions, section) in DV_faqs">
          <h2 class="mt-8 text-xl font-bold leading-10 tracking-tight text-gray-900">{{section}}</h2>
          <dl v-for="(ques, index) of questions">
            <div :class="{'mt-4': index > 0 }">
              <button @click="toggleExpandCollapse" type="button" class="flex w-full items-start justify-start text-left text-gray-900" aria-controls="faq-0" aria-expanded="false">
                <span class="mr-2 flex h-7 items-center">
                  <svg class="open-icon feather feather-plus-square" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                  <svg class="close-icon hidden feather feather-minus-square" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                </span>
                <span class="text-base font-semibold leading-7">{{ques.question}}</span>
              </button>
              <dd class="answer hidden ml-8 text-base leading-2 text-gray-600" v-html="ques.answer"></dd>
            </div>
          </dl>
        </div> -->

        <!-- Uncomment to show simple text design (Make sure to comment upper section) -->
        <div v-for="(questions, section) in DV_faqs">
          <h2 class="mt-8 text-xl font-bold leading-10 tracking-tight text-gray-900">{{section}}</h2>
          <dl v-for="(ques, index) of questions">
            <div :class="{'mt-4': index > 0 }">
              <dt class="text-base font-semibold leading-2 text-gray-900">{{ques.question}}</dt>
              <dd class="text-base leading-2 text-gray-600" v-html="ques.answer"></dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <c2ScheduleDemo ref="scheduleDemoPopupModal"/>

</template>

<script>
  import c2ScheduleDemo from "../components/c2ScheduleDemo"
  import m3RenderingSettings from "../mixins/m3RenderSettings"

  export default {
    name: "p3faqs",
    mixins: [ m3RenderingSettings ],
    components: { c2ScheduleDemo },


    data() {
      return {
        DV_loginUrl: "https://dashboard.synergy-auto-solutions.com/login",
         DV_signUpUrl: "https://dashboard.synergy-auto-solutions.com/account-setup",
        DV_backgroundImageUrl: 'url(' + require('@/assets/header_BG.png') + ')',
        DV_faqs: {
          "Getting Started": [
            { 
              question: "How long does the account sign up process take?",
              answer: `The account setup can be completed in 5-10 minutes. Start by visiting this <a class="text-blue-500" href="https://dashboard.synergy-auto-solutions.com/account-setup">link.`
            },
            { 
              question: "How do I train my employees on using this platform?",
              answer: "While the platform's dashboard is intuitive and easy to grasp, we provide comprehensive training materials for all new users. Further details are provided upon sign-up."
            }
          ],
          "Pricing & Billing": [
            {
              question: "How much does Synergy Auto Solutions cost monthly and how does billing work?",
              answer: `The base rate is $170/month. Monthly usage is added to each invoice based on the past 30 days. Details can be found on our <a class="text-blue-500" href="https://www.synergy-auto-solutions.com/pricing">pricing page</a>. The initial bill is only $170, with the first month's usage billed in arrears on your second invoice.`
            },
            {
              question: "How much can I expect to pay for monthly usage?",
              answer: `Costs depend on software utilization. For a detailed calculation guide, visit our <a class="text-blue-500" href="https://www.synergy-auto-solutions.com/pricing">pricing page</a>.`
            },
            {
              question: "What commitment is required?",
              answer: "Our service is month-to-month. Canceling means you have access until the end of the current billing cycle, followed by one final usage bill."
            }
          ],
          "Platform Features": [
            {
              question: "Why was Synergy Auto Solutions created?",
              answer: "Synergy was developed to offer full-service yards an affordable tool to integrate texting while managing their customer database, focusing specifically on the automotive recycling industry."
            },
            {
              question: "How does Synergy Auto Solutions support my online reputation?",
              answer: "We help boost your reputation by automatically sending review request links to customers post-transaction."
            },
            {
              question: "Which payment processors are compatible with Synergy Auto Solutions?",
              answer: "We offer two integrated processors to choose from during account setup. If you use another common payment processor, inform us for possible integration."
            },
            {
              question: "How can customers confirm their transactions?",
              answer: "Customers can opt to receive an email receipt containing your business details upon successful mobile transaction completion."
            },
            {
              question: "What multimedia types can be shared on Synergy?",
              answer: "The platform supports sending images, PDFs, and videos."
            },
            {
              question: "How does the platform function as a CRM tool for automotive recycling businesses?",
              answer: "Synergy manages customer contacts, businesses, associations, ratings, notes, and conversation histories."
            },
            {
              question: "How can team members collaborate on conversations within the platform?",
              answer: "Sales reps can invite colleagues to customer conversations or have private chats visible only to business employees."
            }
          ],
          "Security & Transactions": [
            {
              question: "How does Synergy Auto Solutions prevent fraudulent transactions?",
              answer: "For transactions requiring verification, customers must submit a selfie along with a photo of their government-issued identification, such as a driver's license or passport. We then employ facial recognition technology and biometric data for identity confirmation."
            },
            {
              question: "How are potential chargebacks addressed?",
              answer: "Chargebacks are managed by your card processor and bank. While we integrate with several card processors, they will primarily handle chargeback disputes."
            }
          ],
          "Background & Name Origin": [
            {
              question: "Who are the creators behind Synergy Auto Solutions?",
              answer: "Our creators have deep ties to the automotive recycling industry, specializing in software for self-service auto recyclers. Recognizing an opportunity, they expanded to cater to full-service recyclers."
            },
            {
              question: "Why is it named Synergy Auto Solutions?",
              answer: "\"Synergy\" symbolizes our aim to work in harmony with the automotive recycling industry, offering solutions tailored to boost sales and efficiency."
            }
          ]
        }
      }
    },

    mounted() {
      // 
    },

    methods: {
      toggleExpandCollapse(e) {
        const buttonEle = e.target.closest("button");
        const closeIconEle = buttonEle.querySelector('.close-icon');
        const openIconEle = buttonEle.querySelector('.open-icon');
        const answerEle = buttonEle.nextElementSibling;

        closeIconEle.classList.toggle("hidden");
        openIconEle.classList.toggle("hidden");
        answerEle.classList.toggle("hidden");

      },
      showScheduleDemoModal() {
          this.$refs.scheduleDemoPopupModal.showSelf();
      },
      gotoHomePage() {
          this.$router.push('/home')
        },
    },

    computed: {
      // 
    }
  }
</script>
