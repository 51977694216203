<template>
  <PopupModal ref="scheduleDemoPopupModal" :P_largeModal="true" :P_showClose="true" @close-modal="closeSelf">
    
    <template v-slot:title>
      <div class="w-full mx-auto flex-col items-center justify-center">
        <div
          class="flex flex-row"
          :class="{'pl-[17vw]' : DV_activeRenderMode <= DV_renderModePhoneNarrow}"
          >
            <img
              :class="{
                'w-[3.5vw] h-[3.5vw] max-h-[7vh] max-w-[7vh]': DV_activeRenderMode >= DV_renderModeTabletWide,
                'w-[10vw] h-[10vw]': DV_activeRenderMode === DV_renderModeTabletNarrow,
                'w-[12vw] h-[12vw]': DV_activeRenderMode === DV_renderModePhoneNarrow,
                'w-[12.1vh] h-[12.1vh]': DV_activeRenderMode === DV_renderModePhoneWide,

              }"
              src="../assets/synergy_icon.png"
            />
            <div class="flex flex-col gap-0 items-center ml-2">
              <span 
              :class="{
                'text-2xl font-extrabold tracking-widest leading-5': DV_activeRenderMode !== DV_renderModeTabletNarrow,
                'text-5xl font-extrabold tracking-widest leading-11': DV_activeRenderMode === DV_renderModeTabletNarrow,
                }"
              >SYNERGY</span>
              <span 
              :class="{
              'text-xl font-normal tracking-tigher': DV_activeRenderMode !== DV_renderModeTabletNarrow,
              'text-3xl font-normal tracking-tigher' : DV_activeRenderMode === DV_renderModeTabletNarrow
             }">Auto Solutions</span>
            </div>
          </div>
        
          <h3 class="font-bold"> Schedule A Demo</h3>
        </div>
     </template>
    <template v-slot:icon>
      
    </template>
    <template v-slot:body>
      <div class="block mt-4 border border-gray-400 shadow-sm p-3 rounded-xl">
        Please fill out the fields below and we will have someone contact you within 2 business days.
        <div class="flex flex-col items-start w-full">
          <span class="mt-2 text-left ">Your Name: </span>
          <input class="w-full" v-model="DV_name"  type="text" name="name">
          <span class="mt-2 text-left ">Phone Number: </span>
          <input v-model="DV_phoneNumber" style="width:10rem;" type="text" name="phone_number">
          <span class="mt-2 text-left">Business Name: </span>
          <input class="w-full" v-model="DV_businessName" type="text" name="business-name">
          <span class="mt-2 text-left">Email Address: </span>
          <input class="w-full" v-model="DV_emailAddress"  type="text" name="email-address">
          <span class="mt-2 text-left">Please summarize your business needs below to help us provide you with relevant information during your demo. </span>
          <textarea class="w-full" v-model="DV_notes" rows="4"  name="notes"></textarea>
          <div v-show="DV_response['message'].length > 0" :class="[DV_response['success'] ? 'text-green-400' : 'text-red-400']">{{ DV_response["message"] }}</div>
          <div class="mt-5 px-4 py-2 text-2xl rounded font-semibold green-gradient cursor-pointer" @click.stop="scheduleDemo"
            >Submit Request</div>
        </div>
      </div>
    </template>
  </PopupModal>
</template>

<script>
  import PopupModal from "./PopupModal";
  import sendScheduleDemoRequest from "../includes/server_calls/p1s3/p1s3e21.ts";
  import m3RenderingSettings from "../mixins/m3RenderSettings"


  export default {
    name: "c2ScheduleDemo",

    components: { PopupModal },
        mixins: [ m3RenderingSettings ],


    data() {
      return {
        DV_name: "",
        DV_businessName: "",
        DV_phoneNumber: "",
        DV_emailAddress: "",
        DV_notes: "",
        DV_response: {success: false, message: ""}
      }
    },

    mounted() {
    },

    methods: {
      scheduleDemo() {
        sendScheduleDemoRequest(this.DV_name,this.DV_phoneNumber,this.DV_businessName,this.DV_emailAddress,this.DV_notes).then((resp) => {
          this.DV_response["success"] = true;
          this.DV_response["message"] = "Demo request successfully sent. We will have someone contact you within 2 business days";
          
          setTimeout(() => {
            this.DV_response["message"] = ""; 
            this.closeSelf();
          }, 2000)
        }).catch((err) => {
          this.DV_response["success"] = false;
          this.DV_response["message"] = "Something went wrong. Please try again later."

          setTimeout(() => {
            this.DV_response["message"] = "";
            this.closeSelf();
          }, 2000)
        });
      },

      showSelf() {
        this.$refs.scheduleDemoPopupModal.show();
      },

      closeSelf() {
        this.$refs.scheduleDemoPopupModal.hide();
      }
    }

  }
</script>