import { createRouter, createWebHistory } from "vue-router";
import p1HomePage from "../components/p1Homepage.vue";
import termsOfService from "../views/termsOfService.vue";
import paymentOfService from "../views/paymentOfService.vue";
import privacyPolicy from "../views/privacyPolicy.vue";
import usePolicy from "../views/usePolicy.vue"
import p2pricing from "../views/p2pricing.vue"
import p3faqs from "../views/p3faqs.vue"

const router = createRouter ({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            component: p1HomePage
        },
        {
            path: "/home",
            component: p1HomePage
        },

        {
            path: "/terms",
            component: termsOfService
        },

        {
            path: "/payments",
            component: paymentOfService
        },

        {
            path: "/privacy",
            component: privacyPolicy
        },

        {
            path: "/use",
            component: usePolicy
        },

        {
            path: "/pricing",
            component: p2pricing
        },

        {
            path: "/faqs",
            component: p3faqs
        },

        {
            path: "/website-widget",
            component: p1HomePage
        }
    ]
});

export default router;