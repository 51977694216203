<template>
    <div class="flex top-[1.5rem] absolute">
      <img class="ml-6 w-[4%] h-[10%]"
        src="../assets/synergy_icon.png"
      /> 

      <img class="w-[13%] h-[10%]"
          src="../assets/synergy_text_black.png"/>
    </div>
    <div
      class="flex justify-center h-[100vh]"
      :style="{
        backgroundImage: 'url(' + require('@/assets/header_BG.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover'
      }"
    >
      <div class="w-3/4 mt-40 bg-white p-6 overflow-scroll h-[75vh] border border-gray-900 rounded">
        <h3 class="page-heading">Privacy Policy</h3>
        <div class="text-justify page-body">
          <p>
              Synergy Auto Solutions (<a class="text-blue-500 hover:text-blue-800 underline" href="https://www.synergy-auto-solutions.com">https://www.synergy-auto-solutions.com</a>), including all of its related applications, dashboards, or platforms (individually and collectively, 
              “Services”), is owned and operated by Texan Rewards LLC. Synergy Auto Solutions (“Synergy,” “we,” or “us,”) values your privacy. This privacy policy (“Privacy Policy”) describes the types of information we may collect from any individual or entity, including, but not 
              limited to, Clients, Customers, Authorized Users, and end users (“you”), who accesses or uses Synergy and our practices for collecting, using, maintaining, protecting, and disclosing that information. By using our Services, you agree to the collection, use, disclosure, 
              and procedures this Privacy Policy describes. Beyond the Privacy Policy, your use of our Services is also subject to our Synergy's <a class="text-blue-500 hover:text-blue-500 underline" href="https://www.synergy-auto-solutions.com/terms">Terms of Service</a> (“Terms of Service”) 
              and our <a class="text-blue-500 hover:text-blue-500 underline" href= "https://www.synergy-auto-solutions.com/use">Synergy's Acceptable Use Policy</a> (“Acceptable Use Policy”). All capitalized terms not defined in this Privacy Policy have the same meaning given in our Terms of Service.
          </p>
          <br>
          <p>
            This Privacy Policy applies to information we collect:
          </p>
  
          <ul style="margin-top: 0;" class="pl-10 list-disc">
              <li class="!list-item">Through the Services.</li>
              <li class="!list-item">In email, text, and other electronic messages between you and the Services.</li>
              <li class="!list-item">Through mobile and desktop applications you download from the Services or a third-party app store, which provide dedicated non-browser-based interaction between you and the Services.</li>
              <li class="!list-item">When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy.</li>
          </ul>
          <br>
  
          <p>This Privacy Policy does not apply to information collected by us offline.</p>
          <br>
  
          <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Services. 
              By accessing or using our Services, you agree to this Privacy Policy. This Privacy Policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of the Services after we make changes is deemed 
              to be acceptance of those changes. If you do not provide your information when requested, you may not be able to use our Services if that information is necessary to provide you with our Services or if we are legally required to collect it.
          </p>
    
          <ol style="margin-top: 0;" class="list-number mb-4">
            <li>
              <span class="font-bold">INFORMATION WE COLLECT ABOUT YOU AND HOW WE COLLECT IT</span>
              <ol>
                <li>
                  <p>
                    <span class="underline">What We Collect.</span> We collect several types of information from and about users of our Services, including:
                  </p>
                  <ol>
                    <li>
                      Personal information, by which you may be personally identified, such as name, postal address, email address, telephone number, or social security number;
                    </li>
  
                    <li>
                      Information that is about you but individually does not identify you;
                    </li>
  
                    <li>
                      If you share it through our Services, including with another user of our Services, Personally Identifiable Information;
                    </li>
  
                    <li>
                      Information necessary to make financial transactions, such as your billing address, other addresses related to you, and credit card or other payment information; and/or
                    </li>
  
                    <li>
                      About your internet connection, the equipment you use to access our Services, and usage details.
                    </li>
                  </ol>
                </li>
    
                <li>
                  <p>
                    <span class="underline">How We Collect This Information.</span>
                  </p>
  
                  <ol>
                      <li>Directly from you when you provide it to us.</li>
                      <li>Automatically as you use our Services. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</li>
                      <li>From third parties, for example, our business partners or from publicly available sources such as certain third-party review sites.</li>
                  </ol>
                </li>
    
                <li>
                  <p>
                    <span class="underline">Information You Provide to Us.</span> The information we collect through our Services may include:
                  </p>
  
                  <ol>
                    <li>
                      Registration and Profile Information. Information that you provide by filling in forms through our Services. This includes information provided at the time of registering to use our Services, subscribing to our 
                      Services, signing up to receive information about our Services, posting material, or requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and 
                      when you report a problem with our Services. If you sign up for an account, register to use our Services, purchase our products, or sign up to receive information about our Services, we may ask you for your, or
                      your customer’s, first and last name, email address, phone number, physical address, P.O. box or other postal address, login information, and any other information we collect.
                    </li>
  
                    <li>
                      Communications with Synergy. Information when you contact us directly such as records and copies of your correspondence (including email addresses), as well as communications with other parties as you use the Services. 
                      For example, when you contact our Customer Support Team, we will receive your name, mobile phone number, the contents of a message, and any other information you choose to provide.
                    </li>
  
                    <li>
                      Communications Using the Services with Others. You also may provide information to others through the Services and your communications may be transmitted to other users of the Services or third parties (collectively, 
                      “User Contributions”). Any User Contributions you post or transmit to others is done at your own risk. We cannot always control the actions of other users of the Services, or a third party outside the Services, with 
                      whom you may choose to share your User Contributions.
                    </li>
  
                    <li>
                      Survey Information. Your responses to surveys that we might ask you to complete for research purposes such as customer success and marketing strategy.
                    </li>
  
                    <li>
                      Payment information. When you add billing information or a credit card number to your account or make a purchase, we will use a third-party service 
                      provider to collect and store your payment information.
                    </li>
  
                    <li>
                      Search Information. Your search queries on the Services.
                    </li>
  
                    <li>
                      Images. We collect any images that you submit to our Services.
                    </li>
  
                    <li>
                      Audio and Voice. We may collect audio through the Services, such as voice recordings that may be transcribed by us or a third party.
                    </li>
                  </ol>
                </li>
    
                <li>
                  <p>
                    <span class="underline">Information We Collect Through Automatic Data Collection Technologies.</span> The information we collect automatically may include personal information. As you navigate through and interact with our Services, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:
                  </p>
  
                  <ol>
                    <li>
                      Usage Information. Details of your use of our Services, including traffic data, location data, logs, and other communication data and the resources that you access and use through the Services. To help us understand how you use our Services and to help us improve them, we automatically receive information about your interactions with our Services, such as the ads you click on, 
                      pages or other content you view, the searches you conduct, purchases you make, your comments, and the dates and times of your visits.
                    </li>
  
                    <li>
                      Location Information. When you use our Services, if you allow us, we will receive your precise location information. We also infer your more general location information (for example, your internet protocol (IP) address may indicate your more general geographic region.
                    </li>
  
                    <li>
                      Device Information. We receive information about the device and software you use to access our Services, including IP address, web browser type, operating system version, phone carrier and manufacturer, application installations, device identifiers, other equipment identifiers, mobile advertising identifiers, and push notification tokens.
                    </li>
  
                    <li>
                      Information from Cookies and Similar Technologies. We and third-party partners collect information using cookies, pixel tags, or similar technologies. Our third-party partners, such as analytics and advertising partners, may use these technologies to collect information about your online activities over time and across different services.
                    </li>
  
                    <li>
                      Widget Information. Other websites may integrate Synergy’s widgets (such as Synergy Webchat). When you visit a site with a Synergy widget embedded, we may receive certain information about you, including information about the web page you visited, your IP address, and other information about your device. Synergy and the widget can recognize 
                      you across websites containing Synergy’s widgets, and the widget may be used to show personalized content or advertising. We know when you interact with a widget, and websites containing the widgets may receive this information.
                    </li>
  
                    <li>
                      Text Information. We may facilitate text messages between you and your customers. In the process, we may receive information about these communications, such as the date and time of the SMS message, the parties’ phone numbers, and the content of any SMS messages.
                    </li>
  
                    <li>
                      User Feedback. We receive information about review and surveys and give our clients information about ratings and reviews.
                    </li>
  
                    <li>
                      Profile Information. Inferences drawn from any of the information above to create a profile about you that may reflect, for example, your preferences, characteristics, and behavior, including for account security purposes or to enhance our Services to you.
                    </li>
  
                    <li>
                      Information you provide to Third Parties. If you choose to link our Services to a third-party account, we may receive information about you, including your profile information, photo, and use of the third-party account. Additionally, we may receive information about you from third parties such as data or marketing partners and combine it with other information we have about you.
                    </li>
                  </ol>
  
                  <p class="mt-4">We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking).</p>
                </li>
    
                <li>
                  <p>
                    <span class="underline">Methods for Automatic Data Collection.</span> The technologies we use for automatic data collection may include:
                  </p>
  
                  <ol>
                    <li>
                      Cookies (or browser cookies). A cookie is a small text file containing a string of alphanumeric characters placed on the hard drive of your computer. We may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to our 
                      Services. (Please review your web browser’s “Help” file to learn the proper way to modify your cookie settings. Please note that if you delete or choose not to accept cookies from the Service, you may not be able to utilize the features of the Services to their fullest potential.) You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, 
                      if you select this setting, you may be unable to access certain parts of our Services. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Services.
                    </li>
  
                    <li>
                      Flash Cookies. Certain features of our Services may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Services. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see Choices About How We Use and Disclose Your Information.
                    </li>
  
                    <li>
                      Web Beacons. Our Services and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for example, to count users who have visited those pages or opened on email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).
                    </li>
                  </ol>
  
                </li>
                
                <p class="mt-4">We may not always collect personal information automatically, but we may tie this information to personal information about you that you provide to us or that we collect from other sources.</p>
              </ol>
            </li>
    
            <li>
              <span class="font-bold">THIRD-PARTY USE OF COOKIES AND OTHER TRACKING TECHNOLOGIES</span>
              
              <p>
                Some content or applications, including advertisements, on the Services are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our Services. The information they collect may be associated with your personal information 
                or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.
              </p>
              <br>
  
              <p>
                We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see Choices About How We Use and Disclose Your Information.
              </p>
            </li>
    
            <li>
              <span class="font-bold">HOW WE USE YOUR INFORMATION</span>
              
              <p class="mt-2">We use information that we collect about you or that you provide to us, including any personal information:</p>
  
              <ol>
                <li>
                  <p>
                    To present our Services and related content to you.
                  </p>
                </li>
    
                <li>
                  <p>
                    To personalize and improve your experience on our Services, such as presenting tailored content, speeding up your search, recognizing when you return to our Services, and storing information about your preferences, allowing us to customize our Services according to your individual interests.
                  </p>
                </li>
    
                <li>
                  <p>
                    For marketing purposes, such as customizing and communicating informational or product offerings, promotions, and advertising materials that may be useful, relevant, valuable or otherwise of interest to you. We may also use it to estimate our audience size and usage patterns. If you do not want us to use your information in this way, please adjust your user preferences in your account profile or contact us as
                    <a class="text-blue-500 hover:text-blue-800 underline" href="mailto:info@texnrewards.com">info@texnrewards.com</a>. For more information, see Choices About How We Use and Disclose Your Information. We may use the information we have collected from you to enable us to display advertisements to our advertisers' target audiences. Even though we do not disclose your personal information for these purposes without 
                    your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.
                  </p>
                </li>
    
                <li>
                  <p>
                    To send you text messages and push notifications.
                  </p>
                </li>
    
                <li>
                  <p>
                    To provide, maintain, improve, and enhance our Services.
                  </p>
                </li>
  
                <li>
                  <p>
                    To provide you with information, products, or services that you request from us.
                  </p>
                </li>
  
                <li>
                  <p>
                    To facilitate transactions and payments.
                  </p>
                </li>
  
                <li>
                  <p>
                    To provide you with notices about your account or subscription, including expiration and renewal notices.
                  </p>
                </li>
  
                <li>
                  <p>
                    To carry out our obligations and enforce our rights arising from any contract(s) entered into between you and us, including for billing and collection.
                  </p>
                </li>
  
                <li>
                  <p>
                    To notify you about changes to our Services or any products or services we offer or provide though it.
                  </p>
                </li>
  
                <li>
                  <p>
                    To allow you to participate in interactive features on our Services.
                  </p>
                </li>
  
                <li>
                  <p>
                    To communicate with you, provide you with updates and other information that you request, respond to comments and questions, and otherwise provide customer support.
                  </p>
                </li>
  
                <li>
                  <p>
                    To market additional or new products or services to you.
                  </p>
                </li>
  
                <li>
                  <p>
                    To find and prevent fraud and respond to trust and safety issues that may arise.
                  </p>
                </li>
  
                <li>
                  <p>
                    To de-identify and aggregate information collected through the Services and use it for any lawful purpose.
                  </p>
                </li>
  
                <li>
                  <p>
                    For compliance purposes, including enforcing our <a class="text-blue-500 hover:text-blue-800 underline" href="https://www.synergy-auto-solutions.com/terms">Terms of Service</a> or other legal rights, or as may be required by applicable laws and regulations or as requested by any judicial process or governmental agency.
                  </p>
                </li>
  
                <li>
                  <p>
                    To fulfill any other purpose for which you provide it.
                  </p>
                </li>
  
                <li>
                  <p>
                    In any other way we may describe when you provide the information.
                  </p>
                </li>
                
                <li>
                  <p>
                    For any other purpose with your consent.
                  </p>
                </li>
              </ol>
  
              <p class="mt-4">We may also maintain the information we collect or associate it with personal information we collect in other ways or receive from third parties.</p>
              <p class="mt-4">We retain your personal information for as long as necessary to achieve the purpose of the collection unless we are required by law to keep it longer.</p>
            </li>
    
            <li>
              <span class="font-bold">DISCLOSURE OF YOUR INFORMATION</span>
              
              <ol>
                <li>
                  <p>
                    <span class="underline">Aggregate and Anonymous Information.</span> We may disclose aggregated information about our users, and information that does not identify any individual, for any business purpose.
                  </p>
                </li>
    
                <li>
                  <p>
                    <span class="underline">Personal Information.</span> We may disclose personal information that we collect or you provide as described in this privacy policy:
                  </p>
  
                  <ol>
                    <li>
                      Affiliates. We may share any information we receive with our subsidiaries, affiliates, and partners for any of the purposes described in this Privacy Policy.
                    </li>
  
                    <li>
                      Vendors and Service Providers. We may share any information we receive with vendors contractors, service providers, and other third parties retained in connection with the provision of our Services or who we use to support our business.
                    </li>
  
                    <li>
                      Third Party App Integrations. If you connect a third-party application to our Services, we may share information with that third party.
                    </li>
  
                    <li>
                      Analytics Partners. We use web analytics services such as Google Analytics to collect and process certain analytics data. These services may also collect information about your use of other websites, apps, and online resources. You can learn about Google’s practices by going to
                      <a class="text-blue-500 hover:text-blue-800 underline" href="http://www.google.com/policies/privacy/partners/">https://www.google.com/policies/privacy/partners/</a> and opt-out of them by downloading the Google Analytics opt-out browser add-on, available at 
                      <a class="text-blue-500 hover:text-blue-800 underline"  href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
                    </li>
  
                    <li>
                      Social Networking Platforms and Other Online Services. Our Services allow you to, upon your direction, share information with social networking services, such as Facebook or Instagram. You understand and agree that the use of your information by any social networking websites 
                      will be governed by the privacy policies of these third-party platforms and your settings on that platform. We encourage you to review their privacy policies.
                    </li>
  
                    <li>
                      Marketing. We may disclose your information to nonaffiliated third parties for their direct marketing purposes as well as our direct marketing purposes. If you would like to opt out of such disclosures, please contact us at
                      <a class="text-blue-500 hover:text-blue-800 underline"  href="mailto:info@texnrewards.com">info@texnrewards.com</a>.
                    </li>
                    
                    <li>
                      Other Users. Our Services enable you to, among other things, communicate with others. Your name, username, and other profile information may be viewable and searchable by other users. The content you post to the Services may be displayed on the Services and viewable by other users 
                      by default. We are not responsible for the other users’ use of available information.
                    </li>
  
                    <li>
                      Sale, Acquisition, Merger or Other Asset Transfers. We may share information to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Synergy's assets, whether as a going concern or as part 
                      of bankruptcy, liquidation, or similar proceeding, in which personal information held by Synergy about our users is among the assets transferred.
                    </li>
  
                    <li>
                      To Fulfill the Purpose for which You Provide. We may share information at your direction. For example, if you give us an email address or other contact information as a referral, we may use that email address to contact the referred party.
                    </li>
  
                    <li>
                      As Disclosed. We may share information for any other purpose disclosed by us when you provide the information.
                    </li>
  
                    <li>
                      With Consent. We may share information with your consent.
                    </li>
                  </ol>
                </li>
    
                <li>
                  <p>
                    <span class="underline">Other Possible Disclosures of Personal Information.</span> We may also disclose your personal information:
                  </p>
  
                  <ol>
                    <li>
                      As Required by Law and Similar Disclosures. In cooperation with governmental authorities and in our sole discretion, we may access, preserve, and disclose information about you if we believe it is necessary or appropriate to: (a) comply with law enforcement requests and legal process, 
                      such as in connection with a court order or subpoena; (b) respond to your requests; or (c) if we believe a disclosure of such information is necessary to protect your, our, or other’s property, rights, or safety. For the avoidance of doubt, the disclosure of information about you may occur 
                      if you post any objectionable content on or through the Services.
                    </li>
  
                    <li>
                      To enforce or apply our <a class="text-blue-500 hover:text-blue-800 underline" href="https://www.synergy-auto-solutions.com/terms">Terms of Service</a>, <a class="text-blue-500 hover:text-blue-800 underline" href="https://www.synergy-auto-solutions.com/use">Acceptable Use Policy</a>
                      and other agreements, including, but not limited to, for billing and collection purposes.
                    </li>
  
                    <li>
                      If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Synergy, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
                    </li>
                  </ol>
                </li>
    
              </ol>
            </li>
    
            <li>
              <span class="font-bold">CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION</span>
              <p class="mt-2">We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:</p>
  
              <ol>
                <li>
                  <p>
                    <span class="underline">Communications with Other Users.</span> The Services allow you to communicate with other users. Some of those communications are intended to be public, such as a review that is left on a third-party website. Other communications may be private or intended by you or 
                    another user to be private. We cannot always control the actions of other users with whom you communicate through our Services, so you must be aware that communications you intend to be private could be shared publicly by another user with whom you communicate. Furthermore, by communicating 
                    with other users, you acknowledge and agree that information you post or otherwise make public may be available to the public or other users.
                  </p>
                </li>
    
                <li>
                  <p>
                    <span class="underline">Do Not Track.</span> There is no accepted standard on how to respond to Do Not Track signals, and we do not respond to such signals.
                  </p>
                </li>
    
                <li>
                  <p>
                    <span class="underline">Location Information.</span> You can prevent your device from sharing precise location information at any time through your device’s operating system settings.
                  </p>
                </li>
  
                <li>
                  <p>
                    <span class="underline">Synergy Marketing Communications.</span> You can unsubscribe from our promotional emails via the link provided in the emails or by sending us an email stating your request to
                    <a class="text-blue-500 hover:text-blue-800 underline"  href="mailto:info@texnrewards.com">info@texnrewards.com</a>. You can unsubscribe from our other promotional communications by replying “STOP” or by sending us an email stating your request to
                    <a class="text-blue-500 hover:text-blue-800 underline"  href="mailto:info@texnrewards.com">info@texnrewards.com</a>. Even if you out-out of receiving promotional messages from us, you will continue to receive administrative messages from us. For example, 
                    if we have sent you a promotional email, you may send us a return email asking to be omitted from future email distributions, but this opt-out will not apply to information provided to Synergy as a result of a product purchase, warranty registration, product service experience or other transactions.
                  </p>
                </li>
  
                <li>
                  <p>
                    <span class="underline">Disclosure of Your Information for Third-Party Advertising.</span> If you do not want us to share your personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by sending us an email with your request to 
                    <a class="text-blue-500 hover:text-blue-800 underline"  href="mailto:info@texnrewards.com">info@texnrewards.com</a>.
                  </p>
                </li>
                
                <li>
                  <p>
                    <span class="underline">Tracking Technologies and Advertising.</span> You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies, please note that some parts of our Services may then be inaccessible or not function properly.
                  </p>
                </li>  
              </ol>
  
              <p class="mt-4">
                If you choose not to provide us with information we collect, some features of our Services may not work as intended. Our Services may contain links to other websites, products, or services that we do not own or operate. We are not responsible for the privacy practices of these third parties, and we do not control third parties’ collection or use of your information. Please be aware that this Privacy Policy does not apply 
                to your activities on these third-party services or any information you disclose to these third parties. We encourage you to read their privacy policies before providing information to them. These third parties may provide you with ways to choose not to have your information collected or used in this way. Furthermore, we do not control third parties' collection or use of your information to serve interest-based advertising. 
                However, these third parties may provide you with ways to choose not to have your information collected or used in this way.
              </p>
  
              <p class="mt-4">
                Some states may offer its residents additional personal information rights and choices. Please see Your States Privacy Rights for more information.
              </p>
            </li>
    
            <li>
              <span class="font-bold">INTERNATIONAL USERS</span>
  
              <p>
                Our Services are hosted in the United States. If you choose to use the Services from other regions of the world with laws governing data collection and use that may differ from United States law, then please note that you are transferring your personal information outside of those regions to the United States for storage and processing. Also, we may transfer your data from the United States to other countries or regions in 
                connection with storage and processing of data, fulfilling your requests, and operating the Services. Your providing of information, including personal information, on or to the Services constitutes your consent to such transfer, storage, and processing.
              </p>
            </li>
    
            <li>
              <span class="font-bold">CANADIAN USERS</span>
  
              <p class="mt-4">
                If you are a Canadian user accessing or using Synergy from Canada, the following terms apply in respect of your personal information:
              </p>
  
              <p class="mt-4">
                You have the right to access and correct your personal information, Our Services may already provide you with functionalities to do this, but if these functionalities are not helpful, you can nevertheless exercise these rights. 
                To do so, you can reach us at <a class="text-blue-500 hover:text-blue-800 underline"  href="mailto:info@texnrewards.com">info@texnrewards.com</a>, or by mail at:
                <br><br>
                Synergy Auto Solutions<br>
                5605 FM 423<br>
                STE 500-341<br>
                Frisco, TX 75036<br>
              </p>
  
              <p class="mt-4">
                We will try to help you with your request free of charge. However, we may request that you pay a reasonable fee, such as if you request a transcript or a reproduction by mail. We will only do so if we are authorized by law, and if we notified you before and you’ve accepted the fees. To avoid any fraudulent request and protect your personal information, we may ask that you provide a proof of identity with your request. 
                We will not use such personal information for any other purposes. We will respond to your request within thirty (30) days, unless agreed otherwise. If your request is denied, we will notify you in writing, and provide you with detailed motives and information on how to contest our decision.
              </p>
  
              <p class="mt-4">
                The Office of the Privacy Commissioner of Canada drafted <a class="text-blue-500 hover:text-blue-800 underline"  href="https://www.priv.gc.ca/en/">this FAQ</a> to help you access your personal information when it is held by a business. You can also contact the Office of the Privacy Commissioner of Canada’s Information Centre:
              </p>
  
              <p class="mt-4">
                <span class="underline">Telephone</span><br>
                9:00 am to 4:00 pm EST<br>
                Toll-free: 1-800-282-1376
              </p>
  
              <p class="mt-4">
                <span class="underline">Mailing address</span><br>
                Office of the Privacy Commissioner 30<br>
                Victoria Street<br>
                Gatineau, Québec<br>
                K1A 1H3<br>
              </p>
  
              <p class="mt-4">
                If you have any issue with how we collect, use or disclose your personal information, or how we responded to your request, please let us know. We will do our best to improve our processes to make certain that it does not happen again. We will also provide you with additional information about our practices if you would like us to do so. If you are still not satisfied, you can lodge a complaint to the Office of the Privacy Commissioner of Canada using
                <a class="text-blue-500 hover:text-blue-800 underline" href="https://www.priv.gc.ca/en/report-a-concern/file-a-formal-privacy-complaint/">this online form</a>, or to your local privacy regulators.
              </p>
            </li>
    
            <li>
              <span class="font-bold">CHILDREN UNDER THE AGE OF 13</span>
              <p>
                Our Website is not intended for children under 13 years of age. No one under age 13 may provide any information through or on the Services. We do not knowingly collect personal information from children under 13. If you are under 13, do not:
              </p>
  
              <ol>
                <li>
                  <p>
                    use or provide any information through or on our Services or through any of its features,
                  </p>
                </li>
    
                <li>
                  <p>
                    register on to use the Services,
                  </p>
                </li>
    
                <li>
                  <p>
                    make any purchases through the Services,
                  </p>
                </li>
  
                <li>
                  <p>
                    use any of the interactive or public comment features of the Services, or
                  </p>
                </li>
  
                <li>
                  <p>
                    provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or username you may use.
                  </p>
                </li>
              </ol>
  
              <p class="mt-4">
                If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at
                <a class="text-blue-500 hover:text-blue-800 underline"  href="mailto:info@texnrewards.com">info@texnrewards.com</a>.
              </p>
            </li>
    
            <li>
              <span class="font-bold">ACCESSING AND CORRECTING YOUR INFORMATION</span>
              <p class="mt-4">
                You can review and change your personal information by logging into the Services and visiting your account profile page.
              </p>
  
              <p class="mt-4">
                You may also send us an email at <a class="text-blue-500 hover:text-blue-800 underline"  href="mailto:info@texnrewards.com">info@texnrewards.com</a> to request access to, correct, or delete any personal information that you have provided to us. If you have a user 
                account with us, we cannot delete all of your personal information except by also deleting your user account. We may not accommodate a request to change or delete information if we believe the change or deletion would violate any law or legal requirement, cause 
                Synergy to violate its rights or legal obligations, or cause the information to be incorrect.
              </p>
  
              <p class="mt-4">
                If you delete your User Contributions from the Services, copies of your User Contributions may remain viewable in cached and archived pages or might have been copied or stored by other Services users. Proper access and use of information provided through the 
                Services, including User Contributions, is governed by our <a class="text-blue-500 hover:text-blue-800 underline"  href="https://www.synergy-auto-solutions.com/terms">Terms of Service</a>.
              </p>
            </li>
    
            <li>
              <span class="font-bold">DATA SECURITY</span>
              <p class="mt-4">
                Synergy values the security of your information. We have implemented reasonable security measures that are designed to protect the information we maintain.
              </p>
  
              <p class="mt-4">
                The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain features of our Services, you are responsible for keeping this password confidential. We ask you not to share your 
                password with anyone. We urge you to be careful about giving out information when using certain aspects of the services such as the Webchat feature. The information you share in public areas may be viewed by other users of the Services.
              </p>
  
              <p class="mt-4">
                Unfortunately, the transmission of information via the internet is not completely secure. As our Services are hosted electronically, we can make no guarantees as to the security or privacy of your information. Although we do our best to protect your personal 
                information, we cannot guarantee the security of your personal information transmitted through our Services. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.
              </p>
            </li>
  
            <li>
              <span class="font-bold">CHANGES TO OUR PRIVACY POLICY</span>
              <p class="mt-4">
                It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will notify you by email to the primary email address specified in your account. The date the privacy policy was last revised 
                is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Services and this privacy policy to check for any changes.
              </p>
            </li>
  
            <li>
              <span class="font-bold">CONTACT INFORMATION</span>
              <p class="mt-4">
                You can update your account and profile information through your profile settings. If you have questions about your privacy on the Services or this Privacy Policy, please contact us at:
              </p>
              <br>
              <a class="text-blue-500 hover:text-blue-800 underline"  href="mailto:info@texnrewards.com">info@texnrewards.com</a>, or<br>
              5605 FM 423<br>
              STE 500-341<br>
              Frisco, TX 75036<br>
              <br>
  
              To register a complaint or concern, please refer to our dispute resolution instructions found in our <a class="text-blue-500 hover:text-blue-800 underline" href="https://www.synergy-auto-solutions.com/terms">Terms of Service</a>.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      name: "privacyPolicy",
  
      data() {
        return {
          // 
        }
      },
  
      mounted() {
        // 
      },
  
      methods: {
        // 
      },
  
      computed: {
        // 
      }
    }
  </script>
