import Vue from "vue";
import  { Ref,ref } from "vue";


// All global variables we'll use as window.VARIABLE
declare global {
  interface Window {
    G_dev_live_flag_force: any;
    G_dev_flag: any;
    G_vue_instance: any;
    G_web_widget_base_url: string;
    G_base_url: any;
    G_cloud_p1s3_base_url: string;
    G_cloud_p1s5_base_url: string;
    G_cloud_functions_base_url: string;
    G_loaded_includes: any;
    G_last_created_recycler_business_uid: string;
    G_last_created_recycler_location_uid: string;
    G_show_unfinished_features: boolean;
    G_active_render_mode: Ref<number>;
    G_render_mode_desktop_wide: number;
    G_render_mode_desktop: number;
    G_render_mode_tablet_narrow: number;
    G_render_mode_tablet_wide: number;
    G_render_mode_phone_narrow: number;
    G_render_mode_phone_wide: number;
  }
}

const detectDeviceType = () => {

  let new_mode = window.G_render_mode_desktop;
  let screen_ratio = window.innerWidth / window.innerHeight;
  let phone_narrow_vote = 0;
  let phone_wide_vote = 0;
  let tablet_narrow_vote = 0;
  let tablet_wide_vote = 0;
  let desktop_vote = 0;
  let desktop_wide_vote = 0;


  console.log(screen_ratio);

  if (window.innerWidth > 600 && window.innerWidth < 1200) {
    tablet_wide_vote += 1;
  }
  if (window.innerWidth > 1000 && window.innerHeight > 700) {
    desktop_vote += 1;
  }
  if (window.innerWidth > 2000) {
    desktop_wide_vote += 5;
  }
  if (window.innerWidth <= 600) {
    phone_narrow_vote += 1;
  }

  if (window.innerWidth <= 390) {
    phone_narrow_vote += 10;
  }

  if (window.innerHeight < 600 &&  screen_ratio > 1.8) {
    phone_wide_vote += 10;
  }

  if (screen_ratio < 0.8 && window.innerWidth > 600) {
    tablet_narrow_vote += 10;
  }
  else if (screen_ratio <= 0.8) {
    phone_narrow_vote += 10;
  }
   if (screen_ratio <= 1.6) {
    tablet_wide_vote += 1;
  }
  if (screen_ratio >= 1.7) {
    desktop_vote += 1;
  }
  if(screen_ratio >= 2) {
    desktop_wide_vote += 1;
  }

  if(window.innerWidth < 900) {
    desktop_vote = 0;
    desktop_wide_vote =0;

  }


  let vote_type_index = [
    window.G_render_mode_phone_narrow,
    window.G_render_mode_phone_wide,
    window.G_render_mode_tablet_narrow,
    window.G_render_mode_tablet_wide,
    window.G_render_mode_desktop,
    window.G_render_mode_desktop_wide]
  let highest_score = 0;
  let vote_values = [phone_narrow_vote,
    phone_wide_vote,
    tablet_narrow_vote,
    tablet_wide_vote,
    desktop_vote,
    desktop_wide_vote];
  for(let index in vote_values)  
  {
    if(vote_values[index] > highest_score) {
      highest_score = vote_values[index];
      new_mode = vote_type_index[index];
    }
  }

  window.G_active_render_mode.value = new_mode;
  console.log(window.G_active_render_mode.value);
}

const runFirst = () => {

  window.G_render_mode_desktop_wide = 6;
  window.G_render_mode_desktop = 5;
  window.G_render_mode_tablet_wide = 4;
  window.G_render_mode_tablet_narrow = 3;
  window.G_render_mode_phone_narrow = 2;
  window.G_render_mode_phone_wide = 1;

  window.G_active_render_mode = ref(0);
  
  detectDeviceType();

  // produtction versus dev stuff
  window.G_dev_live_flag_force = false;
  window.G_dev_flag = false;
  window.G_show_unfinished_features = false;

  let current_version = "2023-07-28:10:19 MST"
  let browser_version = localStorage.getItem('synergy_cache_version');
    
  if (browser_version !== current_version || browser_version === null) {
    localStorage.clear();
    localStorage.setItem('synergy_cache_version',current_version)
    console.log('clearing local storage');
  }

  if (
    (window.location.hostname.toString().includes("localhost") ||
      window.location.hostname.toString().includes("127.0.0.1")) &&
    window.G_dev_live_flag_force === true
  ) {
    window.document.title = "Synergy Auto Solutions - Development";
    window.G_cloud_p1s3_base_url = "https://p1s3-dot-dev-synergy-auto-solutions.uc.r.appspot.com/"
    window.G_cloud_p1s5_base_url = "https://p1s5-dot-dev-synergy-auto-solutions.uc.r.appspot.com/"
    window.G_cloud_functions_base_url = "https://us-central1-dev-synergy-auto-solutions.cloudfunctions.net/"
    window.G_base_url = "https://us-central1-dev-synergy-auto-solutions.net"
    window.G_web_widget_base_url = "https://devc.synergy-auto-solutions.com/"
    window.G_dev_flag = false;
  }
  else if (
    window.location.hostname.toString().includes("dev") ||
    window.location.hostname.toString().includes("localhost") ||
    window.location.hostname.toString().includes("127.0.0.1")
  ) {
    window.document.title = "Synergy Auto Solutions - Development";
    window.G_cloud_p1s3_base_url = "https://p1s3-dot-dev-synergy-auto-solutions.uc.r.appspot.com/"
    window.G_cloud_p1s5_base_url = "https://p1s5-dot-dev-synergy-auto-solutions.uc.r.appspot.com/"
    window.G_cloud_functions_base_url = "https://us-central1-dev-synergy-auto-solutions.cloudfunctions.net/"

    window.G_base_url = "https://us-central1-dev-synergy-auto-solutions.net"
    window.G_web_widget_base_url = "https://devc.synergy-auto-solutions.com/"
    window.G_dev_flag = true;
  }

  //forcing live flag should only work on localhost

  else if ( window.location.hostname.toString().includes("synergy-auto-solutions.com")) {
    window.document.title = "Synergy Auto Solutions";

    window.G_dev_flag = false;
    window.G_cloud_p1s3_base_url = "https://p1s3-dot-dev-synergy-auto-solutions.uc.r.appspot.com/"
    window.G_cloud_p1s5_base_url = "https://p1s5-dot-dev-synergy-auto-solutions.uc.r.appspot.com/"
    window.G_base_url = "https://us-central1-dev-synergy-auto-solutions.net"
    window.G_web_widget_base_url = "https://c.synergy-auto-solutions.com/"
  }


  window.addEventListener('resize', () => {
    detectDeviceType();

  });

};


export default runFirst;