<template>
  <PopupModal ref="webWidgetDemoPopupModal" :P_largeModal="true" :P_showClose="true" @close-modal="closeSelf">
    
    <template v-slot:title> <h3 class="font-bold"> Web Widget Demo</h3> </template>
    <template v-slot:body>
      <div class="mx-auto mt-4 border border-gray-400 shadow-sm p-3 rounded-xl">
        <div v-if="DV_modalOpen === true" class="mx-auto self-center" >
          <!-- Synergy-Auto-Solutions widget begin -->
          <iframe style="min-height: 600px; min-width:400px;" name="SynergyAutoSolutionsWidget" src="https://devc.synergy-auto-solutions.com/?recycler_business_uid=g7Z6Y73bX9o3nTNrn3wj&recycler_location_uid=MaZW31EIhVfNMEndSYYn&type=part&name=Texn Rewards"></iframe>
          <!-- Synergy-Auto-Solutions widget end -->
        </div>        
      </div>
    </template>
  </PopupModal>
</template>

<script>
  import PopupModal from "./PopupModal";

  export default {
    name: "c2ScheduleDemo",
//href=""
    components: { PopupModal },

    data() {
      return {
        DV_modalOpen: false,
      }
    },

    mounted() {
    },

    methods: {

      showSelf() {
        this.DV_modalOpen = true;
        this.$refs.webWidgetDemoPopupModal.show();
      },

      closeSelf() {
        this.$refs.webWidgetDemoPopupModal.hide();
        this.DV_modalOpen = false;
      }
    }

  }
</script>