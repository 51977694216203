import runFirst from "./run_first"
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "./assets/tailwind.css";
import "./assets/base.css";




const app = createApp(App)

runFirst();

app.use(router);
app.mount("#app");
