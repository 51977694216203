<template>    
    <div
      class="grid auto-rows-min"
      :style="{
            backgroundImage: DV_backgroundImageUrl,
          }"
        :class="{
          'p1-section-1-wide-tablet': DV_activeRenderMode == DV_renderModeTabletWide,
          'p1-section-1-narrow-tablet': DV_activeRenderMode == DV_renderModeTabletNarrow,
          'p2-section-1-above-wide-tablet': DV_activeRenderMode > DV_renderModeTabletWide,
          '.p2-phone' : DV_activeRenderMode <= DV_renderModePhoneNarrow
          }"
    >
    <!-- alignment spacer 1 for top of curve in background image-->
      <span
        v-if="DV_activeRenderMode >= DV_renderModePhoneNarrow"
          class="row-start-1 col-start-1"
          :class="{
            'w-[13.1vw]': DV_activeRenderMode === DV_renderModeDesktop,
            'w-[12vw]': DV_activeRenderMode === DV_renderModeDesktopWide,
            'w-[3vw]': DV_activeRenderMode === DV_renderModeTabletWide,
            'w-[7vw]': DV_activeRenderMode === DV_renderModeTabletNarrow,
            }"
      ></span>
          <!-- alignment spacer 2 for middle of curve in background image -->

      <span v-if="DV_activeRenderMode >= DV_renderModeTabletNarrow"
          class="row-start-1 col-start-2"
          :class="{
            'w-[11.5vw]': DV_activeRenderMode === DV_renderModeDesktop,
            'w-[10.1vw]': DV_activeRenderMode === DV_renderModeDesktopWide,
            'w-[15vw]': DV_activeRenderMode === DV_renderModeTabletWide,
            'w-[6.5vw]': DV_activeRenderMode === DV_renderModeTabletNarrow,
            }"
      ></span>

      <nav class="flex items-center"
          :class="{
          'row-start-1 col-start-1 col-span-8 flex-row items-center mx-auto gap-10 justify-between': DV_activeRenderMode >= DV_renderModeTabletWide,
          'row-start-1 col-start-7 flex-col gap-8 justify-center': DV_activeRenderMode <= DV_renderModeTabletNarrow,
           }">
            <a 
           v-if="DV_activeRenderMode >= DV_renderModeTabletNarrow"
           class="text-center px-8 py-2 text-xl rounded font-semibold green-gradient cursor-pointer"
           @click.stop="showScheduleDemoModal"
           >Schedule A Demo</a>
            <a
           class="text-center py-2 text-xl rounded font-semibold green-gradient cursor-pointer" 
           :class="
           {'px-8': DV_activeRenderMode > DV_renderModePhoneNarrow,
           'px-2' : DV_activeRenderMode <= DV_renderModePhoneNarrow,
          }"
           :href="DV_signUpUrl">Create An Account</a>
                     

          <a
          v-if="DV_activeRenderMode > DV_renderModeTabletNarrow"
          class="text-center px-8 py-2 text-xl rounded font-semibold green-gradient cursor-pointer" href="/home">Home</a>
      </nav>

      <div
            class="flex flex-row items-center row-start-1"
            :class="{
              'col-span-5 col-start-2': DV_activeRenderMode === DV_renderModeTabletNarrow,
              'col-span-4 col-start-1 pl-3': DV_activeRenderMode <= DV_renderModePhoneNarrow,
              'col-start-8 mr-12': DV_activeRenderMode >= DV_renderModeTabletWide && DV_activeRenderMode <= DV_renderModeDesktop,
              'col-start-7': DV_activeRenderMode === DV_renderModeDesktopWide
          }"
          @click.stop="gotoHomePage"
          >
            <img
              :class="{
                'w-[3.5vw] h-[3.5vw] max-h-[7vh] max-w-[7vh]': DV_activeRenderMode >= DV_renderModeTabletWide,
                'w-[10vw] h-[10vw]': DV_activeRenderMode === DV_renderModeTabletNarrow,
                'w-[14vw] h-[14vw]': DV_activeRenderMode === DV_renderModePhoneNarrow,
                'w-[12.1vh] h-[12.1vh]': DV_activeRenderMode === DV_renderModePhoneWide,

              }"
              src="../assets/synergy_icon.png"
            />
            <div class="flex flex-col gap-0 items-center ml-2">
              <span 
              :class="{
                'text-2xl font-extrabold tracking-widest leading-5': DV_activeRenderMode !== DV_renderModeTabletNarrow,
                'text-7xl font-extrabold tracking-widest leading-11': DV_activeRenderMode === DV_renderModeTabletNarrow,
                }"
              >SYNERGY</span>
              <span 
              :class="{
              'text-xl font-normal tracking-tigher': DV_activeRenderMode !== DV_renderModeTabletNarrow,
              'text-6xl font-normal tracking-tigher' : DV_activeRenderMode === DV_renderModeTabletNarrow
             }">Auto Solutions</span>
            </div>
          </div>
          

          <!-- Headings -->
          <h2 class="row-start-2 mx-auto font-bold tracking-tight text-gray-900 text-center mt-2"
          :class="{
            'col-start-1 col-span-8 text-4xl mb-4' : DV_activeRenderMode >= DV_renderModeTabletWide,
            'col-start-1 col-span-7 text-3xl' : DV_activeRenderMode <= DV_renderModeTabletNarrow
        }">
            Streamlining Auto Recycling Like Never Before</h2>
          <span class="row-start-3 text-center mx-auto text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl"
          :class="{
            'col-start-1 col-span-8 text-2xl' : DV_activeRenderMode >= DV_renderModeTabletWide,
            'col-start-1 col-span-7 text-2xl mt-4' : DV_activeRenderMode <= DV_renderModeTabletNarrow
        }">
            Transparent Pricing Built to Scale with Your Business</span>

          <div class="row-start-4 text-center"
          :class="{
            'col-start-3 col-span-4' : DV_activeRenderMode >= DV_renderModeTabletWide,
            'col-start-1 col-span-7 mx-auto pl-5 pr-5' : DV_activeRenderMode <= DV_renderModePhoneNarrow,
            'col-start-3 col-span-5' : DV_activeRenderMode === DV_renderModeTabletNarrow

        }"
          >
            <p class=" text-gray-600 mt-2"
            :class="{
              'text-xl text-left': DV_activeRenderMode >= DV_renderModeTabletWide && DV_activeRenderMode <= DV_renderModeDesktop,
              'text-3xl' : DV_activeRenderMode === DV_renderModeDesktopWide,
              'text-xl' : DV_activeRenderMode <= DV_renderModeTabletNarrow,
              'text-left' : DV_activeRenderMode <= DV_renderModePhoneNarrow
              }"
            >At Synergy Auto Solutions, we believe in clarity and simplicity. That's why our pricing model is transparent, straightforward, and built to scale with your business. Whether you're operating from one location or multiple locations, we offer a full-service automotive recycling communication platform designed to revolutionize how you manage your transactions and communications.</p>
          </div>

          <!-- Features + Price -->
          <div class="row-start-5 mx-auto rounded-2xl bg-gray-50 py-2 text-center ring-1 ring-inset ring-gray-900/5 flex flex-col mt-5 pl-5 pr-5"
          :class="{
            'col-start-1 col-span-8': DV_activeRenderMode >= DV_renderModeTabletWide,
            'col-start-1 col-span-7' : DV_activeRenderMode <= DV_renderModeTabletNarrow
        }"
          >
              <p class="text-base font-semibold text-gray-600">Single Auto Recycler Location</p>
              <p class="mt-1 flex items-baseline justify-center gap-x-2">
                <span class="text-3xl font-bold tracking-tight text-gray-900">$170/month</span>
              </p>
              <p class=" text-sm leading-6 text-gray-500">Base price + usage</p>
              <!-- <a :href="DV_signUpUrl" class="green-gradient mt-1 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign Up Now</a> -->
          </div>

          
          <div class="row-start-6  mx-auto flex flex-col"
          :class="{
            'w-[80vw] col-start-1 col-span-8': DV_activeRenderMode >= DV_renderModeDesktop,
            'col-start-1 col-span-7 w-[95vw]': DV_activeRenderMode <= DV_renderModeTabletWide,
        }">
            <!-- Usage based -->
          
          <p id="usage-price-table" 
          class="mt-4 pb-4 pt-3 pl-4 text-left text-gray-600 bg-gray-100"
          :class="{
            'text-xl': DV_activeRenderMode >= DV_renderModeDesktop,
            'text-lg': DV_activeRenderMode <= DV_renderModeTabletNarrow,
        }">
            In addition to the $170/month base fee, usage fees are assessed every 30 days based on actual platform usage for identity verifications and text messaging.
          </p>
          
          <table class=" mx-auto divide-y divide-gray-300 w-full">
            <thead>
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-lg font-semibold text-gray-900">Service</th>
                <th scope="col" class="px-2 py-3.5 text-left text-lg font-semibold text-gray-900">Price per Unit</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr>
                <td class="py-2 pl-4 pr-3 text-lg text-gray-500">Identity Verification</td>
                <td class="px-2 py-2 text-lg text-gray-500 !border-r-0">$1.75/<br v-if="DV_activeRenderMode===DV_renderModePhoneNarrow">verification</td>
              </tr>

              <tr>
                <td class="py-2 pl-4 pr-3 text-lg text-gray-500">SMS Text Messages</td>
                <td class="px-2 py-2 text-lg text-gray-500 !border-r-0">$0.02/<br v-if="DV_activeRenderMode===DV_renderModePhoneNarrow">message</td>
              </tr>

              <!--
              <tr>
                <td class="py-2 pl-4 pr-3 text-lg text-gray-500">Creating Payment Links</td>
                <td class="px-2 py-2 text-lg text-gray-500 !border-r-0">$0.0045/<br v-if="DV_activeRenderMode===DV_renderModePhoneNarrow">link</td>
              </tr>

              <tr>
                <td class="py-2 pl-4 pr-3 text-lg text-gray-500">Sending Live Chat <br v-if="DV_activeRenderMode===DV_renderModePhoneNarrow">Text Messages</td>
                <td class="px-2 py-2 text-lg text-gray-500 !border-r-0">$0.00045/<br v-if="DV_activeRenderMode===DV_renderModePhoneNarrow">message</td>
              </tr>

              <tr>
                <td class="py-2 pl-4 pr-3 text-lg text-gray-500">Sending Live Chat <br v-if="DV_activeRenderMode===DV_renderModePhoneNarrow">Images/Videos/Files</td>
                <td class="px-2 py-2 text-lg text-gray-500 !border-r-0">$0.00045/<br v-if="DV_activeRenderMode===DV_renderModePhoneNarrow">message</td>
              </tr>

              <tr>
                <td class="break-all py-2 pl-4 pr-3 text-lg text-gray-500">Creating New Business<br v-if="DV_activeRenderMode===DV_renderModePhoneNarrow">/Consumer/Employee</td>
                <td class="px-2 py-2 text-lg text-gray-500 !border-r-0">$0.00075/<br v-if="DV_activeRenderMode===DV_renderModePhoneNarrow">contact</td>
              </tr>
              -->
            </tbody>
          </table>

          <p class="mt-0 pt-3 pl-4 pr-4 text-left  text-gray-500 bg-gray-100 rounded-2xl"
          :class="{
            'text-lg' : DV_activeRenderMode <= DV_renderModeTabletWide,
            'text-xl' : DV_activeRenderMode >= DV_renderModeDesktop

        }">
            Our platform primarily uses a web application for the bulk of the conversation, saving costs over traditional text messaging. While we start the conversation with text messages, we use them strategically, for example, to remind customers of an unpaid invoice or to request an online review after a successful purchase. This balance between web application and SMS results in significant cost savings.
          </p>
        </div>

        <!-- Call to action -->
        <div class="w-full row-start-7 mx-auto"
        :class="{
          'col-start-2 col-span-6': DV_activeRenderMode === DV_renderModeTabletWide,
          'col-start-1 col-span-8': DV_activeRenderMode >= DV_renderModeDesktop,
          'col-start-1 col-span-7': DV_activeRenderMode <= DV_renderModeTabletNarrow
      }">
          <div class="px-6 py-12 sm:px-6 sm:py-12 lg:px-8">
            <div class="mx-auto max-w-2xl text-center">
              <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Boost your productivity.<br>Start using our platform today.</h2>
              <p class="mx-auto mt-6 max-w-xl text-lg leading-8">Experience the future of auto recycling today. Click below to schedule a demo or to sign up.</p>
              <div class="mt-10 flex items-center justify-center gap-x-6">
                <a href="javascript:;" @click.stop="showScheduleDemoModal" class="green-gradient rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Schedule a Demo</a>
                <!-- <a :href="DV_signUpUrl" class="green-gradient rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign Up Now</a> -->
              </div>
            </div>
          </div>
        </div>
    </div>

    <c2ScheduleDemo ref="scheduleDemoPopupModal"/>
  </template>
  
<script>
  import c2ScheduleDemo from "../components/c2ScheduleDemo"
  import m3RenderingSettings from "../mixins/m3RenderSettings"


    export default {
      name: "p2pricing",

      components: { c2ScheduleDemo },
      mixins: [ m3RenderingSettings ],

      data() {
        return {
          DV_loginUrl: "https://dashboard.synergy-auto-solutions.com/login",
          DV_signUpUrl: "https://dashboard.synergy-auto-solutions.com/account-setup",
          DV_backgroundImageUrl: 'url(' + require('@/assets/header_BG.png') + ')',
        }
      },
  
      mounted() {
        if(this.DV_activeRenderMode === this.DV_renderModePhoneNarrow) {
        this.DV_backgroundImageUrl = '';
      }
      },
  
      methods: {
        gotoHomePage() {
          this.$router.push('/home')
        },

        showScheduleDemoModal() {
          this.$refs.scheduleDemoPopupModal.showSelf();
        }
      },
  
      computed: {
        // 
      }
    }
  </script>
