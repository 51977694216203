
export default {
    data() {
      return {      
      DV_renderModeDesktop: window.G_render_mode_desktop,
      DV_renderModeDesktopWide: window.G_render_mode_desktop_wide,
      DV_renderModeTabletWide: window.G_render_mode_tablet_wide,
      DV_renderModeTabletNarrow: window.G_render_mode_tablet_narrow,
      DV_renderModePhoneNarrow: window.G_render_mode_phone_narrow,
      DV_renderModePhoneWide: window.G_render_mode_phone_wide,      
      DV_activeRenderMode: 0,
      DV_showUnfinishedFeatures: window.G_show_unfinished_features,
      }
    },
    mounted() {
      this.DV_activeRenderMode = window.G_active_render_mode;
    }
  }