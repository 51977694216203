<template>
    <div class="content-center flex flex-col"
          :class="{
          'items-center flex-wrap': DV_activeRenderMode > DV_renderModePhoneNarrow,
        }">

      
      <!-- section 1 conent-->

      <div class=""
      :style="{
            backgroundImage: DV_backgroundImageUrl,
          }"
        :class="{
          'p1-section-1-wide-tablet grid auto-rows-min': DV_activeRenderMode == DV_renderModeTabletWide,
          'p1-section-1-narrow-tablet grid auto-rows-min': DV_activeRenderMode == DV_renderModeTabletNarrow,
          'p1-section-1-above-wide-tablet grid auto-rows-min': DV_activeRenderMode > DV_renderModeTabletWide,
          'p1-section-1-phone grid auto-rows-min': DV_activeRenderMode <= DV_renderModePhoneNarrow,
          }"
          >
        <!-- section 1  column 1 content -->

        <!--set colum1 spacer for top of curve in background image-->  
        <span
          class="row-start-1 col-start-1"
          :class="{
            'w-[3vw]': DV_activeRenderMode >= DV_renderModeDesktop,
            'w-[5vw]': DV_activeRenderMode === DV_renderModeTabletWide,
            'w-[7vw]': DV_activeRenderMode === DV_renderModeTabletNarrow,
            'w-[9vw]': DV_activeRenderMode <= DV_renderModePhoneNarrow,
            }"
          ></span>

        <!--set colum1 spacer for middle of the curve in background image-->  
        <span v-if="DV_activeRenderMode >= DV_renderModeTabletNarrow"
          class="row-start-1 col-start-2"
          :class="{
            'w-[9.8vw]': DV_activeRenderMode >= DV_renderModeDesktop,
            'w-[6.1vw]': DV_activeRenderMode === DV_renderModeTabletWide,
            'w-[16.1vw]': DV_activeRenderMode === DV_renderModeTabletNarrow,
            'w-[9vw]': DV_activeRenderMode <= DV_renderModePhoneNarrow,
            }"
          ></span>


          <div
            class="flex flex-row items-center justify-center"
            :class="{
              'row-start-1 col-span-5 col-start-2': DV_activeRenderMode === DV_renderModeTabletNarrow,
              'row-start-1 col-span-1 col-start-8 mr-5': DV_activeRenderMode === DV_renderModeTabletWide,

              'row-start-1 col-span-6 col-start-2': DV_activeRenderMode <= DV_renderModePhoneNarrow,
              'absolute right-0 top-0 mt-2 mr-5': DV_activeRenderMode === DV_renderModeDesktop,
              'row-start-1 col-start-7' : DV_activeRenderMode === DV_renderModeDesktopWide
          }"
          >
            <img
              :class="{
                'w-[3.5vw] h-[3.5vw] max-h-[7vh] max-w-[7vh]': DV_activeRenderMode >= DV_renderModeTabletWide,
                'w-[2.1vw] h-[2.1vw] max-h-[7vh] max-w-[7vh]': DV_activeRenderMode >= DV_renderModeDesktop,
                'w-[13vw] h-[13vw]': DV_activeRenderMode === DV_renderModeTabletNarrow,
                'w-[14vw] h-[14vw]': DV_activeRenderMode === DV_renderModePhoneNarrow,
                'w-[6vw] h-[6vw]': DV_activeRenderMode === DV_renderModePhoneWide,
              }"
              src="../assets/synergy_icon.png"
            />
            <div class="flex flex-col gap-0 items-center ml-2">
              <span 
              :class="{
                'text-2xl font-extrabold tracking-widest leading-5': DV_activeRenderMode !== DV_renderModeTabletNarrow,
                'text-7xl font-extrabold tracking-widest leading-11': DV_activeRenderMode === DV_renderModeTabletNarrow,
                }"
              >SYNERGY</span>
              <span 
              :class="{
              'text-xl font-normal tracking-tigher': DV_activeRenderMode !== DV_renderModeTabletNarrow,
              'text-6xl font-normal tracking-tigher' : DV_activeRenderMode === DV_renderModeTabletNarrow
             }">Auto Solutions</span>
            </div>
          </div>
        

          <span class="font-bold text-center mx-auto row-start-2 "
              :class="{
              'text-3xl mt-6 col-start-1 col-span-8': DV_activeRenderMode >= DV_renderModeTabletWide,
              'text-3xl mt-6 mb-5 pl-4 pr-4 col-start-1 col-span-10': DV_activeRenderMode <= DV_renderModePhoneNarrow,
              'mt-4 text-5xl w-[75vw] mb-5 col-start-2 col-span-9': DV_activeRenderMode === DV_renderModeTabletNarrow,

            }"><span class="text-synergy-green">{{ DV_activeSlideHeaderPart1 }} </span>
              <span class="text-synergy-blue">{{ DV_activeSlideHeaderPart2 }}</span>
          </span>

            


        
        <!--</end> section  column 1 content -->

        <!-- section 1 column 2 content -->
          <nav class="flex items-center"
          :class="{
          'row-start-1 col-start-2 col-span-6 gap-10 flex-row justify-between mx-auto': DV_activeRenderMode === DV_renderModeTabletWide ,
          'row-start-1 col-start-2 col-span-7 gap-10 flex-row justify-between mx-auto': DV_activeRenderMode === DV_renderModeDesktopWide,
          'row-start-1 col-start-1 col-span-8 gap-10 mx-auto': DV_activeRenderMode === DV_renderModeDesktop,
          'row-start-1 col-start-9 flex-col gap-8 justify-center': DV_activeRenderMode === DV_renderModeTabletNarrow,
          'row-start-1 col-start-10 flex-col gap-8 justify-center': DV_activeRenderMode <= DV_renderModePhoneNarrow,
           'flex-col': DV_activeRenderMode <= DV_renderModePhoneNarrow
           }">
            <a 
           v-if="DV_activeRenderMode >= DV_renderModeTabletNarrow"
           class="text-center px-8 py-2 text-xl rounded font-semibold green-gradient cursor-pointer"
           :class="{'whitespace-pre' : DV_activeRenderMode >= DV_renderModeTabletWide}"
           @click.stop="showScheduleDemoModal"
           >Schedule A Demo</a>
           <!-- Create Account
            <a
           class="text-center py-2 text-xl rounded font-semibold green-gradient cursor-pointer" 
           :class="
           {'px-8': DV_activeRenderMode > DV_renderModePhoneNarrow,
           'px-2' : DV_activeRenderMode <= DV_renderModePhoneNarrow,
           'whitespace-pre' : DV_activeRenderMode >= DV_renderModeTabletWide
          }"
           :href="DV_signUpUrl">Create An Account
          </a>
          -->

          <a
          v-if="DV_activeRenderMode > DV_renderModeTabletNarrow"
          class="text-center px-8 py-2 text-xl rounded font-semibold green-gradient cursor-pointer" :href="DV_loginUrl">Go To Dashboard</a>
          </nav>

          <span
              class="row-start-3 mx-auto"
              :class="{
                'col-start-1 col-span-8 text-2xl mt-5 w-[50vw]' : DV_activeRenderMode >= DV_renderModeDesktop,
                'col-start-3 col-span-6  text-center text-2xl mt-5 ': DV_activeRenderMode >= DV_renderModeTabletNarrow && DV_activeRenderMode <= DV_renderModeTabletWide,
                'col-start-1 col-span-10 text-left ml-2 mr-2 text-2xl mb-5 ': DV_activeRenderMode <= DV_renderModePhoneNarrow,
                'col-start-3 col-span-7 text-center text-4xl ': DV_activeRenderMode === DV_renderModeTabletNarrow

                }"
              >
                {{ DV_activeSlideText }}
              </span>              
          <div
            class="flex flex-col w-full items-center text-center row-start-4 col-start-1"
            :class="{
              'mb-3 mt-4': DV_activeRenderMode >= DV_renderModeTabletNarrow,
              'col-span-10' : DV_activeRenderMode <= DV_renderModeTabletNarrow,
              'col-span-8 mt-5': DV_activeRenderMode >= DV_renderModeTabletWide,
            }"
          >            
            
            <img
            v-if="DV_activeRenderMode === DV_renderModeTabletWide"
            style="max-height:70vh;max-width:95vw"
            :src="DV_activeSlideImagePath"
            />

            <img
            v-if="DV_activeRenderMode >= DV_renderModeDesktop"
            style="height:65vh;max-width:99vw"
            :src="DV_activeSlideImagePath"
              />
            <img            
                v-if="DV_activeRenderMode <= DV_renderModePhoneNarrow || DV_activeRenderMode === DV_renderModeTabletNarrow"
                style="width:95vw;"
                :src="DV_activeSlideImagePath"
              />
            </div>

            <div v-if="DV_activeRenderMode <= DV_renderModeTabletNarrow"
              class="flex row-start-5 col-start-1 col-span-10 mt-5 mb-5"
              :class="{
                'flex-row justify-evenly ': DV_activeRenderMode > DV_renderModePhoneNarrow,
                'flex-col gap-5 w-[75vw] mx-auto' : DV_activeRenderMode <= DV_renderModePhoneNarrow,
              }"
            >
              <a 
              v-if="DV_activeRenderMode !== DV_renderModeTabletNarrow"
              class="px-10 py-2 text-2xl rounded font-semibold green-gradient cursor-pointer text-center" @click.stop="showScheduleDemoModal"
              >Schedule a Demo
            </a>
              <a class="px-10 py-2 text-2xl rounded font-semibold green-gradient cursor-pointer text-center" href="/pricing">
                Pricing
              </a>

              <a class="px-10 py-2 text-2xl rounded font-semibold green-gradient cursor-pointer  text-center" href="/faqs">
                FAQs
              </a>
          </div>
        
        <!--</end> section 1  column 2 content -->

      </div>
      <!--</end> section 1 conent-->

      <div
        class="flex w-full"
        :class="{
          'gap-0': DV_activeRenderMode >= DV_renderModeTabletNarrow,
          ' gap-4 ml-2 mt-2 flex-col place-items-center': DV_activeRenderMode <= DV_renderModePhoneNarrow,
        }"
      >
        <div
          class="flex flex-col"
          :class="{
            'items-start gap-6 w-1/2': DV_activeRenderMode > DV_renderModePhoneNarrow
          }"
        >
          <div class="font-bold ml-5 flex flex-col"
          :class="{
                'text-5xl': DV_activeRenderMode > DV_renderModeTabletWide,
                'text-2xl text-center': DV_activeRenderMode <= DV_renderModePhoneNarrow,
                'text-4xl': DV_activeRenderMode === DV_renderModeTabletWide

              }">
              <span class="text-synergy-blue">Synergy Auto </span>          
              <span class="text-synergy-green"> Solutions Benefits</span>
          </div>
          <ul :class="{'text-2xl': DV_activeRenderMode > DV_renderModeTabletWide }">
            <li class="flex items-start mt-2 flex-row gap-3">
              <img class="w-6 mt-1" src="../assets/bullet.svg" />
              <div >
                Efficient queue management to streamline your sales process
              </div>
            </li>
            <li class="flex items-start mt-2 flex-row gap-3">
              <img class="w-6 mt-1" src="../assets/bullet.svg" />
              <div>
                Centralized repository of business and customer contact
                information
              </div>
            </li>
            <li class="flex items-start mt-2 flex-row gap-3">
              <img class="w-6 mt-1" src="../assets/bullet.svg" />
              <div>Seamless payment integration for hassle-free checkout</div>
            </li>
            <li class="flex items-start mt-2 flex-row gap-3">
              <img class="w-6 mt-1" src="../assets/bullet.svg" />
              <div>
                Rate your customers and maintain your online reputation
              </div>
            </li>
            <li class="flex items-start mt-2 flex-row gap-3">
              <img class="w-6 mt-1" src="../assets/bullet.svg" />
              <div>
                Optimize your sales process with comprehensive conversation
                history
              </div>
            </li>
          </ul>
          
        <div v-if="DV_activeRenderMode >= DV_renderModeTabletWide"
      class="flex flex-row w-full ml-5 gap-12 mt-2">
          <a              
          class="px-10 py-2 text-2xl rounded font-semibold green-gradient cursor-pointer text-center" @click.stop="showScheduleDemoModal"
          >Schedule a Demo
        </a>
          <a class="px-10 py-2 text-2xl rounded font-semibold green-gradient cursor-pointer text-center" href="/pricing">
            Pricing
          </a>

          <a class="px-10 py-2 text-2xl rounded font-semibold green-gradient cursor-pointer  text-center" href="/faqs">
            FAQs
          </a> 
          </div>
        </div>
        
        
        <div class="w-2/5">
          <img
            v-if="DV_activeRenderMode > DV_renderModePhoneNarrow"
            class="rounded-3xl"
            src="../assets/SASB_Image.png"
          />
        </div>
      </div>          
          
      <c2ScheduleDemo
        ref="scheduleDemoPopupModal"
      />
      <c3WebWidgetDemo
      ref="webWidgetDemoPopupModal"
      />
  
      <footer class="flex items-center justify-center mt-20">
        <div class="max-w-2xl flex flex-col items-center justify-between gap-6">
          <img width="120" src="../assets/synergy_logo.png" />
          <div class="text-center font-light">
            Synergy Auto Solutions - Your trusted partner for cutting-edge sales
            management and customer data solutions, empowering businesses to
            streamline processes and drive growth.
          </div>
          <div class="mt-5 mb-7 font-bold">© 2024 Synergy Auto Solutions</div>
        </div>
      </footer>

        <!-- Synergy-Auto-Solutions widget begin -->
        <meta name="iframeURL" :content="DV_webWidgetIframeUrl" />
        <link :href='DV_webWidgetCssUrl' rel='stylesheet' type='text/css'>
        <!-- Synergy-Auto-Solutions widget end -->
      </div>
  </template>
  
  <script>
  import c2ScheduleDemo from "./c2ScheduleDemo"
  import c3WebWidgetDemo from "./c3WebWidgetDemo"
  import m3RenderingSettings from "../mixins/m3RenderSettings"
  
  export default {
     name: "p1HomePage",
  
     components : {
      c2ScheduleDemo,
      c3WebWidgetDemo
     },
     mixins: [ m3RenderingSettings ],

  
     data() {
       return {
         DV_loginUrl: "https://dashboard.synergy-auto-solutions.com/login",
         DV_signUpUrl: "https://dashboard.synergy-auto-solutions.com/account-setup",
         DV_currentSlide: -1,
         DV_activeSlideImagePath: "",
         DV_activeSlideText: "",
         DV_activeSlideHeaderPart1: "",
         DV_activeSlideHeaderPart2: "",
         DV_backgroundImageUrl: 'url(' + require('@/assets/header_BG.png') + ')',
         DV_slides: [
          ["active_conversation.png",10000],
          ["widget_process.png",10000],
          ["new_conversation.png",10000],
          ["payment_link_process.png",10000],
          ["id_verification_process.png",10000],
          ["review_link_process.png",10000],
          ["contacts.png",10000],
         ],
         DV_slidesHeadersPart1: [
          `Synergy Dashboard: `,
          ``,
          ``,
          ``,
          ``,
          ``,
          ``
         ],
         DV_slidesHeadersPart2: [
          `Built for Auto Recyclers, by Auto Recyclers`,
          `Intelligent Part Request Routing`,
          `Seamless Texting Conversations`,
          `Effortless and Secure Mobile Payments`,
          `Robust Identity Verification`,
          `Smart Customer Reviews and Ratings`,
          `Unified Contact Management`
         ],
         DV_slidesText: [
          `Unleash the power of text-based communications to maximize your sales efficiency, reduce phone time, and expedite transactions.`,
          `Automate customer inquiries with our Live Chat widget. Let customers select the make, model, and part number, then route requests to your sales team—no interruptions, just efficiency.`,
          `Initiate new text conversations with ease. Transition from phone calls to texts, freeing your sales team while enabling the sending and receiving of images and videos for precise part identification.`,
          `Leverage integration with industry-leading payment processors. Send payment links via text, receive instant notifications, and streamline your transaction process.`,
          `Add an extra layer of security to your large transactions. Enable Stripe Identity Verification for real-time selfie and ID checks, reducing fraud and building customer trust.`,
          `Automatically request reviews post-purchase. Direct positive reviews to your social channels and receive internal alerts for any feedback that’s 3-stars or less. Rate your customers to enhance future interactions.`,
          `Store and manage all your customer interactions and profile information in one centralized hub for quick and easy access.`
         ],
         DV_webWidgetDemoOpen: false,
         // DV_webWidgetDomain: "http://localhost:8081/",
         // DV_webWidgetIframeUrl: "http://localhost:8081/", // ?recycler_business_uid=g7Z6Y73bX9o3nTNrn3wj&recycler_location_uid=MaZW31EIhVfNMEndSYYn&type=part
         // DV_webWidgetCssUrl: 'http://localhost:8081/widget/iframe_popup_css.css',
         
         DV_webWidgetDomain: "https://c.synergy-auto-solutions.com/",
         DV_webWidgetIframeUrl: "https://c.synergy-auto-solutions.com/?recycler_business_uid=wPiliZyaj47UlknD2UGq&recycler_location_uid=CwRpfcili9jqmjV4AJWx&type=part",
         DV_webWidgetCssUrl: 'https://c.synergy-auto-solutions.com/widget/iframe_popup_css.css' 
       }
     },
     mounted() {

      if(this.DV_activeRenderMode === this.DV_renderModePhoneNarrow) {
        this.DV_backgroundImageUrl = ''; 
      }


  
      this.nextSlide();
        if(window.location.href.indexOf("localhost") !== -1 || 
          window.location.href.indexOf("dev-") !== -1
        ) {
          this.DV_loginUrl = "https://dev-dashboard.synergy-auto-solutions.com/login";
          this.DV_signUpUrl = "https://dev-dashboard.synergy-auto-solutions.com/account-setup";
        }


      if (window.location.pathname.includes("website-widget")) {
        let scriptTag = document.createElement("script");
        scriptTag.src = this.DV_webWidgetDomain + 'widget/iframe_popup.js';
        document.body.appendChild(scriptTag);
      }
   },
  
   methods: {
    nextSlide() {
      if(this.DV_currentSlide >=  (this.DV_slides.length -1)) {
        this.DV_currentSlide = 0; 
      } else { 
        this.DV_currentSlide += 1;
      }
      let folder_name = "";
      if(this.DV_activeRenderMode >= this.DV_renderModeDesktopWide) {
        folder_name = "wide_desktop";
      } else if(this.DV_activeRenderMode >= this.DV_renderModeDesktop) {
        folder_name = "desktop";
      } else {
        folder_name ="phone_tablet_narrow";
      }



      this.DV_activeSlideImagePath = require('@/assets/slideshow/' + folder_name + '/' + this.DV_slides[this.DV_currentSlide][0]);
      this.DV_activeSlideHeaderPart1 = this.DV_slidesHeadersPart1[this.DV_currentSlide];
      this.DV_activeSlideHeaderPart2 = this.DV_slidesHeadersPart2[this.DV_currentSlide];
      this.DV_activeSlideText = this.DV_slidesText[this.DV_currentSlide];
      setTimeout(() => {
        this.nextSlide();
  
      }, this.DV_slides[this.DV_currentSlide][1]);
    },
    showScheduleDemoModal() {
          this.$refs.scheduleDemoPopupModal.showSelf();
      },
    showWebWidgetDemoModal() {
        this.$refs.webWidgetDemoPopupModal.showSelf();
    }
   },  
   }
  </script>
  