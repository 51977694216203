<template>
  <div v-show="DV_showModal" class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline"
        :class="modalWidthClasses">
        <div>
          <div v-if="P_showClose" class="text-right">
            <button @click.stop="$emit('close-modal')" class="inline-flex rounded-md p-1.5 text-black-500 hover:bg-gray-100 focus:outline-none focus:bg-black-100 transition ease-in-out duration-150" aria-label="Dismiss">
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
          <div class="w-full text-center">
            <slot name="icon"></slot>
          </div>
          <div class="text-center" :style="P_customStyle" :class="P_customClass">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
              <slot name="title"></slot>
            </h3>
            <div class="mt-2 text-left">
              <slot name="body"></slot>
            </div>
          </div>
        </div>
        <div v-if="hasFooterSlot" class="mt-5 sm:mt-6">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['P_fullModal', 'P_largeModal', 'P_mediumModal', 'P_showClose', 'P_customStyle', 'P_customClass'],
  data() {
    return {
      DV_showModal: false
    }
  },
  methods: {
    show() { this.DV_showModal = true; },
    hide() { this.DV_showModal = false; }
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer
    },
    modalWidthClasses() {
      if (this.P_fullModal) {
        return "sm:max-w-9/12 sm:w-9/12";
      }

      if (this.P_largeModal) {
        return "sm:max-w-7/12 sm:w-7/12";
      }

      if (this.P_mediumModal) {
        return "sm:max-w-2/5 sm:w-2/5";
      }

      return 'sm:max-w-lg sm:w-full';
    } 
  }
}
</script>
