<template>
  <div class="flex top-[1.5rem] absolute">
    <img class="ml-6 w-[4%] h-[10%]"
      src="../assets/synergy_icon.png"
    /> 

    <img class="w-[13%] h-[10%]"
        src="../assets/synergy_text_black.png"/>
  </div>
  
  <div
    class="flex justify-center h-[100vh]"
    :style="{
      backgroundImage: 'url(' + require('@/assets/header_BG.png') + ')',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover'
    }"
  >
    <div class="w-3/4 mt-40 bg-white p-6 overflow-scroll h-[75vh] border border-gray-900 rounded">
      <h3 class="page-heading">Payment Service Terms</h3>
      <div class="text-justify page-body">
        <p>
          By using or accessing Synergy’s Payment Service, as defined herein, or by signing or otherwise accepting any Subscription Documentation referencing these terms, you (“Client”) agree to be bound by the following terms and conditions (“Payment Service Terms”).
        </p>
        <br>
        <p>
          These Payment Service Terms incorporate by this reference the <a class="text-blue-500 hover:text-blue-800 underline" href="https://www.synergy-auto-solutions.com/terms">Synergy Terms of Service</a> which, among other terms, contains provisions governing the 
          resolution of claims (see “Disclaimers”, “Limitations of Liability”, “Dispute Resolution”, “General”). In the event of any conflict or inconsistency between these Payment Service Terms and the Synergy Terms of Service, these Payment Service Terms will govern.
        </p>
        <br>
        <p>
          Capitalized terms used in these Payment Service Terms without a definition have the same meaning as the defined term in the Synergy Terms of Service. All documents linked in these Payment Service Terms are deemed to be incorporated by this reference into, and 
          made part of those documents as updated from time to time. For the avoidance of doubt, all references in the Synergy Terms of Service to the “Agreement” will include these Payment Service Terms.
        </p>

        <ol style="margin-top: 0;" class="list-number">
          <li>
            <span class="font-bold">DEFINITIONS.</span>
            <ol>
              <li>
                <p>
                  <span class="font-bold">“Payment Processing Services”</span> means the ability for you to submit, manage, and complete Transactions with your Customers via the Services.
                </p>
              </li>

              <li>
                <p>
                  <span class="font-bold">“Payment Service Providers”</span> means the third-party service providers used by Synergy to facilitate certain features of the Payment Service, including payment processors (“PPSP”). Synergy may add or remove Payment Processing 
                  Service Providers from time to time.
                </p>
              </li>

              <li>
                <p>
                  <span class="font-bold">“Payment Service”</span> means the Payment Processing Services, together with such related functionality as Synergy may offer from time to time.
                </p>
              </li>

              <li>
                <p>
                  <span class="font-bold">“Personal Data”</span> means information about an identifiable individual, or information that is capable of being used (along or in combination with other information) to identify an individual, (not a company, legal entity, or machine) and is transmitted to or accessible through the Services.
                </p>
              </li>

              <li>
                <p>
                  <span class="font-bold">“Transaction”</span> means a request initiated by Client via the Payment Service for which Client directs Synergy to capture funds for or from a Customer-associated account with respect to a payment from a Customer, and includes the authorization, settlement, and if applicable, disputes, refunds, 
                  and reversals, with respect to such payment request.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <span class="font-bold">SERVICES.</span>
            <ol>
              <li>
                <p>
                  <span class="underline">Use of the Payment Service.</span> Subject to your performance of your duties and obligations under the Agreement (including any additional terms set forth in the applicable Subscription Documentation), Synergy will provide you with access to the Payment Service. Synergy will have no obligation 
                  to provide any services or perform any tasks not specifically set forth in the Agreement (including any applicable Subscription Documentation).
                </p>
              </li>

              <li>
                <p>
                  <span class="underline">Stored Credentials.</span> Synergy may enable functionality in the Payment Service that allows your Customers to store and reuse payment credentials. Stored credentials may be stored on your behalf, or on your Customer’s behalf. If credentials are stored on your behalf, you may subsequently instruct 
                  Synergy to initiate a Transaction with that Customer using those credentials, provided that you do so in compliance with: (i) the express consent that you have received from the Customer, as documented in writing; and (ii) these Payment Service Terms and the PPSP Agreement. Synergy may decline to process any such Transaction 
                  that you initiate until it is satisfied that you have met this compliance obligation. If credentials are stored on behalf of a Customer, then you may not use those credentials to initiate a Transaction – it is up to the Customer to decide whether to reuse those credentials to complete a Transaction. You are not entitled to use 
                  stored credentials other than as specified in this section, and you will never be entitled to directly access or export stored credentials.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <span class="font-bold">PAYMENT PROCESSING SERVICES.</span>
            <ol>
              <li>
                <p>
                  <span class="underline">Account Registration.</span> Client may access and fully use the Payment Processing Services only after a Payment Processing Service Provider account (“PPSP Account”) for Client has been activated and verified within Synergy’s platform. You agree and acknowledge that activation of a PPSP Account is subject 
                  to approval by the relevant Payment Processing Service Provider, and such Payment Processing Service Provider may suspend or terminate your PPSP Account at any time in accordance with the PPSP Agreement (as defined below). The information you provide to Synergy to facilitate the activation and verification of your PPSP Account must 
                  be accurate, current, and complete. Until you have submitted, and Payment Processing Service Provider has reviewed and approved, all required information, your access (if any) to the Payment Processing Services will be on a preliminary, limited basis only, and Synergy may terminate your access at any time and for any reason.
                </p>
              </li>

              <li>
                <p>
                  <span class="underline">PPSP Agreement.</span> Your use of the Payment Processing Services is conditional upon you accepting the terms and conditions imposed by the relevant Payment Processing Service Provider with respect to the aspects of the Payment Service that it facilitates ("PPSP Agreement"). By submitting Transactions via 
                  the Payment Processing Services, you must read and agree to PPSP Agreements of the Payment Processing Service Providers that facilitate the processing of payments. For the avoidance of doubt, Synergy is not a party to any PPSP Agreement.
                </p>
              </li>

              <li>
                <p>
                  <span class="underline">PPSP Account Management.</span> This Agreement describes how your PPSP Account will be managed, including (i) the use and administration of any data about you, your Customers, or your Transactions that is provided to Synergy, and (ii) action that may be submitted via Synergy and your PPSP Account, such as 
                  initiating and refunding Transactions, handling charge backs or disputes, and other functions that are or may become available through your PPSP Account. You consent to Synergy’s disclosure of data (which may include Personal Data) to each Payment Processing Service Provider and to each Payment Processing Service Provider’s disclosure 
                  of data (which may include Personal Data) to Synergy, as the case may be, for the purpose of facilitating the provision of the Payment Processing Services.
                </p>
              </li>

              <li>
                <p>
                  <span class="underline">Responsibility for PPSP Compliance.</span> Synergy is not responsible for any non-compliance by Payment Service Provider with the terms of any PPSP Agreement, any laws, or payment method provider rules, or for the acts or omissions of any Payment Service Provider in providing services to you or your Customers.
                </p>
              </li>

              <li>
                <p>
                  <span class="underline">Termination and Suspension by PPSP.</span> If a Payment Processing Service Provider terminates or suspends your PPSP Agreement or PPSP Account, for any reason, Synergy may immediately terminate or suspend your use of the Payment Processing Services, without any liability to you.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <span class="font-bold">DATA USE.</span>
            <ol>
              <li>
                <p>
                  In addition to the Client’s obligations under the Synergy Terms of Service, Client agrees that where Client provides or makes available to Synergy any Client Data (including without limitation, Personal Data about Customers) in connection with Client’s use of the Payment Service, Client grants Synergy and its affiliates the worldwide, 
                  non-exclusive, royalty-free, perpetual, fully- paid-up, and irrevocable, right to (i) to collect, use, retain, transfer, and disclose Client Data (including to Synergy’s third party service providers) in order to provide the Payment Service to Client and (ii) to enable Synergy to use Client Data to: (a) provide the Payment Service; 
                  (b) mitigate fraud, financial loss, or other harm; (c) analyze and improve, and incorporate Client Data into, Synergy’s products, systems, and tools. Any information, including Personal Data, provided by Client in connection with the Payment Service will be subject to
                  <a class="text-blue-500 hover:text-blue-800 underline" href="https://www.synergy-auto-solutions.com/privacy">Synergy's Privacy Policy</a>.
                </p>
              </li>

              <li>
                <p>
                  Synergy may disclose Client Data pursuant to a data subject access request or consumer data request as required by applicable law, provided that to the extent permitted under applicable law, Synergy notifies Client of any such disclosure. Notwithstanding anything herein to the contrary, Synergy will be permitted to retain and use 
                  Client Data (to the extent such use is permitted under these Payment Service Terms) associated with a dispute after the expiration or termination of your applicable Subscription Documentation. Solely for purposes of the California Consumer Privacy Act of 2018, Cal. Civil Code § 1798.100 et seq. (as may be amended from time to time) 
                  (“CCPA”), (i) Synergy is acting as a Service Provider (as defined in the CCPA) for Client as a Business (as defined in the CCPA), (ii) except as set forth herein, Synergy is prohibited from selling the Client Data or retaining, using, or disclosing the Client Data for any purpose other than for the specific purpose of performing the 
                  Payment Service, (iii) Client hereby instructs Synergy that the Business Purpose includes Synergy (and its service providers) using and retaining the Client Data internally for the benefit of all of Synergy’s clients, including detecting fraud, optimizing e-commerce solutions and similar performance enhancing purposes, and (iv) Synergy 
                  understands and will comply with the restrictions herein.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <span class="font-bold">CLIENT OBLIGATIONS.</span>
            <ol>
              <li>
                <p>
                  <span class="underline">Representations and Warranties.</span> Client warrants and represents that it: (i) will comply with this Agreement; (ii) will ensure that its employees, contractors, and agents will at all times comply with the terms of this Agreement; (iii) will only use the Payment Service for bona fide commercial Transactions with its Customers, which have been authorized by those Customers;
                  <br>
                  (iv) will research any Transaction and, if necessary, contact the Customer before completing or fulfilling the Transaction, if Client is unsure if such Transaction is erroneous or suspicious; (v) will be responsible for managing Client’s relationship with its Customers, including communication, selling, and providing products and services, and handling any disputes or returns with such Customers; (vi) 
                  will be responsible for providing all notices and obtaining all consents as required under applicable laws or regulations in connection with recurring and subscription Transactions; (vii) will be responsible for providing any other required notices and obtaining all consents as required under applicable laws, rules, regulations, or contract obligations governing any other features of the Payment Service 
                  Client uses under this Agreement; and (viii) will ensure that the form and content of any invoices Client generates and sends to its Customers using the Payment Service will comply with applicable law and are sufficient to achieve the legal or tax effects Client is trying to achieve. You affirm that you are solely responsible for the nature and quality of the products or services you provide and for the 
                  delivery, support, refunds, returns, and any other ancillary services you provide to your Customers.
                </p>
              </li>

              <li>
                <p>
                  <span class="underline">Additional Representations.</span> Client further represents and warrants that it: (i) has obtained necessary consent from the Customers in order for Synergy to use Client Data in accordance with these Payment Service Terms, (ii) has provided notices to Customers, as required under applicable law, (iii) if applicable, has obtained consent from Customers to use automated decision making, 
                  (iv) has provided Customers with the ability to exercise any access rights as required under applicable law, and (v) complies with any requirements or limitations regarding the processing of Personal Data and data of minors, in each case, to the extent required under applicable law.
                </p>
              </li>

              <li>
                <p>
                  <span class="underline">Unauthorized Activity.</span> Client is responsible for any activity initiated using credentials issued by Synergy or any Payment
                  Service Provider. Where Synergy incurs any losses based on unauthorized activity initiated by Client or on Client’s behalf, Client is financially liable for such losses. Such losses may be deducted from Client’s PPSP Account, or Synergy may require Client to pay such losses.
                </p>
              </li>

            </ol>
          </li>

          <li>
            <span class="font-bold">REQUIREMENTS, LIMITATIONS AND RESTRICTIONS.</span>
            <ol>
              <li>
                <p>
                  <span class="underline">Compliance with Laws.</span> You must use the Payment Service in a lawful manner, and must obey all laws, rules, and regulations (“Laws”) applicable to your use of the Payment Service. As applicable, this may include compliance with domestic and 
                  international Laws related to the use or provision of financial services, notification and consumer protection, unfair competition, privacy, and false advertising, and any other Laws relevant to Transactions.
                </p>
              </li>

              <li>
                <p>
                  <span class="underline">Prohibited Businesses.</span> You may not use the Payment Service to enable any person (including you) to benefit from any activities Synergy (or Payment Service Provider) has identified as a restricted business or activity (“Prohibited Businesses”), 
                  including those businesses listed in the <a class="text-blue-500 hover:text-blue-800 underline" href="https://www.synergy-auto-solutions.com/use">Synergy Acceptable Use Policy</a> and any that may be listed by your Payment Service Provider, as well as any pornography, gambling, 
                  and/or firearms related businesses, activities, or transactions. “Prohibited Businesses” also includes any country, organization, entity, or person embargoed or blocked by any government, including those on sanctions lists identified by the United States Office of Foreign Asset 
                  Control (OFAC). If you are uncertain whether a category of business or activity is restricted or have questions about how these restrictions apply to you, please contact Synergy.
                </p>
              </li>

              <li>
                <p>
                  <span class="underline">Other Restricted Uses.</span> You may not use the Payment Service to facilitate illegal Transactions or to permit others to use the Payment Service for personal, family, or household purposes. In addition, you may not allow, and may not allow others to: 
                  (i) access or attempt to access non-public systems, programs, data, or services; (ii) copy, reproduce, republish, upload, post, transmit, resell, or distribute in any way, any data, content, or any part of the Payment Service, documentation, or Synergy’s website except as expressly 
                  permitted by applicable Laws; (iii) act as service bureau or pass-through agent for the PPSP Account with no added value to your Customers; (iv) transfer any rights granted to you under this Agreement; (v) work around any of the technical limitations of the Payment Service or enable 
                  functionality that is disabled or prohibited; (vi) reverse engineer or attempt to reverse engineer the Payment Service except as expressly permitted by Laws; or (vii) perform or attempt to perform any actions that would interfere with the normal operation of the Payment Service or affect 
                  use by Synergy’s other users.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <span class="font-bold">FEES, SETTLEMENT & PAYOUT SCHEDULE.</span>
            <ol>
              <li>
                <p>
                  <span class="underline">Fees.</span> In addition to any other Fees due to Synergy under the Subscription Documentation, Client will pay a fee assessed on a per Transaction basis (“Transaction Fee”) and any additional fees for additional features within the Payment Service which may be made 
                  available by Synergy from time to time (the Transaction Fees, together with the additional fees (if any), are “Payment Service Fees”, which form part of the Fees, as that term is defined in the Synergy Terms of Service).
                </p>
              </li>

              <li>
                <p>
                  <span class="underline">Determining Fees.</span> Fees are set by Synergy in its sole discretion, and Fees are payable in accordance with these Payment Service Terms, the Synergy Terms of Service, and your applicable Subscription Documentation. If no Payment Service Fees are specified on your 
                  Subscription Documentation, your use of the Payment Service will be subject to Synergy’s then-current rates, available at the <a class="text-blue-500 hover:text-blue-800 underline" href="https://www.synergy-auto-solutions.com/pricing">Synergy Pricing Page</a>. Synergy may revise the Payment 
                  Service Fees at any time, by providing notice to Client, with the change in Fees effective thirty (30) days from the date of the applicable notice. In addition to the Fees, you must reimburse Synergy for any liabilities incurred by Synergy as a result of your use of the Payment Service in a 
                  manner not permitted by this Agreement or a PPSP Agreement, including penalties and fines. You agree that Synergy will have the right to deduct the applicable Fees (including the Payment Service Fees) and any amounts otherwise due to Synergy from your PPSP Account. If your PPSP Account balance 
                  is insufficient, Synergy may debit the Client Bank Account to recover owed amounts.
                </p>
              </li>

              <li>
                <p>
                  <span class="underline">Settlement.</span> Synergy will facilitate access to your PPSP Account through Synergy Sales Dashboard but settlement of Transaction funds to your Client Bank Account is the sole responsibility of your PPSP. You affirm that the account you designate as your Client Bank 
                  Account for the purposes of this agreement is: (i) owned by you, and (ii) titled in your legal name or trade name. You also affirm that you are authorized to initiate settlements to and debits from the Client Bank Account you designate. You also agree that Synergy has the right to withhold settlement 
                  to your Client Bank Account if we reasonably determine that we have incurred or may incur losses resulting from credit, fraud, or other legal risks associated with your use of the Payment Service. If we exercise our right to withhold the settlement of funds for any reason, we will communicate the general 
                  reason for withholding the funds and give you a timeline and description of the preconditions that must be satisfied for releasing the funds.
                </p>
              </li>

              <li>
                <p>
                  <span class="underline">Payout Schedule.</span> Your PPSP will specify the frequency of payouts of your balance to your Client Bank Account (“Payout Schedule”). Synergy will not be responsible for any nonconformance with the Payout Schedule due to the acts or omissions of any third party (e.g., a 
                  telecommunications provider or financial institution). The payout amount may also take some time to appear in your Client Bank Account balance, due to the operation of systems outside of Synergy’s control.
                </p>
              </li>

            </ol>
          </li>

          <li>
            <span class="font-bold">SUSPENSION & TERMINATION.</span>
            <ol>
              <li>
                <p>
                  Synergy may refuse, condition, or suspend any Transactions that we believe: (i) may violate this Agreement; (ii) are unauthorized, fraudulent, or illegal; or (iii) expose you, Synergy, or others to risks unacceptable to Synergy. If we suspect or know that you are using or have used the Payment 
                  Service for unauthorized, fraudulent, or illegal purposes, we may share any information related to such activity with the appropriate financial institution, regulatory authority, or law enforcement agency consistent with our legal obligations. This information may include information about you, 
                  your Payment Provider Account, your Customers, and Transactions made through your use of Payment the Services.
                </p>
              </li>

              <li>
                <p>
                  Synergy may also suspend your access to and/or use of the Payment Service, your PPSP Account, and your access to funds in your account, or terminate this Agreement, at any time if you are in breach of this Agreement, a PPSP Agreement, or Synergy believes that your continued use of the Payment 
                  Service creates, or is likely to create a risk of harm to Synergy, a Customer, or any other third party.
                </p>
              </li>

              <li>
                <p>
                  Termination of the Payment Service Terms means that you will no longer be authorized to access the Payment Service, which includes the ability to submit Transactions via the Payment Processing Services, however termination will not affect your continued liability with respect to Transactions 
                  that you submit prior to date of termination.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <span class="font-bold">LIMITATIONS ON SYNERGY’S LIABILITY.</span>
            <ol>
              <li>
                <p>
                  Synergy is not responsible for Client’s obligations to its Customers (including properly describing and delivering the goods or services being sold to Customers). You are solely responsible for, and Synergy expressly disclaims all liability for, your compliance with applicable laws and obligations 
                  related to your provision of the goods or services to your Customers. This may include providing customer service, notification and handling of refunds or Customer complaints, provision of receipts, registering your legal entity, or other actions not related to the Services. You agree to indemnify 
                  Synergy for any losses we incur based on your failure to properly describe or deliver goods or services, or comply with your legal or contractual obligations to Payment Service Provider or your Customers.
                </p>
              </li>

              <li>
                <p>
                  This Section is in addition to, and does not limit in any way, the risk allocation provisions of the Synergy Service Terms.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <span class="font-bold">ADDITIONAL SERVICES.</span>
            <ol>
              <li>
                <p>
                  From time to time, Synergy may offer Services that supplement the Payment Service (“Additional Services”). Additional Services are Payment Services that are subject to additional terms and conditions, and you accept the relevant additional terms and conditions when you use an Additional Service.
                </p>
              </li>

              <li>
                <p>
                  Synergy may at any time add or remove services from the list of available Additional Services or change the additional terms that apply to usage of an Additional Service.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "paymentOfService",

    data() {
      return {
        // 
      }
    },

    mounted() {
      // 
    },

    methods: {
      // 
    },

    computed: {
      // 
    }
  }
</script>